


export function monthToNominal(mes) {
    switch (mes) {
        case "01":
            return "Janeiro"

        case "02":
            return "Fevereiro"

        case "03":
            return "Março"

        case "04":
            return "Abril"

        case "05":
            return "Maio"

        case "06":
            return "Junho"

        case "07":
            return "Julho"

        case "08":
            return "Agosto"

        case "09":
            return "Setembro"

        case "10":
            return "Outubro"

        case "11":
            return "Novembro"

        case "12":
            return "Dezembro"

        default:
            return "erro "
    }

}

export function monthToNominalShort(mes) {
    const month = monthToNominal(mes)

    return month.substring(0, 3)
}

export function monthYear(valor) {
    if (valor) {
        valor = valor.split("-", 2)
        valor[1] = monthToNominalShort(valor[1])

        valor = `${valor[1]}/${valor[0]}`
        return valor
    } else {
        return ""
    }
}
export function dayMonthYear(valor) {
    if (valor) {
        valor = valor.split("-")
        valor[1] = monthToNominalShort(valor[1])

        if (valor[2]) {
            valor = `${valor[2]}/${valor[1]}/${valor[0]}`
        } else {
            valor = `${valor[1]}/${valor[0]}`
        }

        return valor
    } else {
        return ""
    }
}

export function expiratioDateCalc(today, expDate) {


    if (today && expDate) {
        let aaaaT = today.slice(0, 4)
        let aaaaE = expDate.slice(0, 4)
        let mmT = today.slice(5, 7)
        let mmE = expDate.slice(5, 7)
        if (parseInt(aaaaT) > parseInt(aaaaE)) {
            //vencido
            return false
        } else if (parseInt(aaaaT) === parseInt(aaaaE)) {
            if (parseInt(mmT) >= parseInt(mmE)) {
                //vencido
                return false
            } else {
                //em dia
                return true
            }
        } else {
            //em dia
            return true
        }


    }

}
export function secondToHour(second) {
    let t = 0
    if (second && second >= 3600) {
        t = parseInt(second / 3600)

        return t
    }
    return "00"
}
export function secondToMinute(second) {
    let t = 0
    if (second) {
        t = parseInt((second / 60) % 60)
        t = t + ""
        if (t.length < 2) {
            return "0" + t
        }
        return t
    }
    return "00"
}

export function secondToSecond(second) {
    let t = 0
    if (second) {
        t = parseInt(second % 60)
        t = t + ""
        if (t.length < 2) {
            return "0" + t
        }
        return t
    }
    return "00"
}

export function secondToHMinS(second) {

    return `${secondToHour(second)}h ${secondToMinute(second)}min ${secondToSecond(second)}s`

}


