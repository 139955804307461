import { useState } from 'react'
import Input from '../../../../shared/components/form/input'

import './formRegisterInfo.css'
/* Function */
import { monthToNominal, monthToNominalShort, monthYear } from "../../Functions/Date"
import FormRecord from './FormRecord'


function FormRegisterInfo({ title, inputName, lastDid, nextDid, handleOnSubmit , permission_create}) {

    const [modalNewRecord, setModalNewRecord] = useState(false)
    const [newRecord, setNewRecord] = useState()
    
   
    const submit = async (data) => {
       
        let response = await handleOnSubmit(data)
        if(response === "success"){
            setModalNewRecord(false)

        }
        

    }

    return (
        <div>
            <div className='form-register-info-box'>
            <label> {title}</label>
                <div className='form-register-info-box-box'>
                    <div className='form-register-info-box-record'>
                        <div> Última realizada: </div>
                        <div>
                            {lastDid ? monthYear(lastDid) : "-------"}
                        </div>
                    </div>
                    <div className='form-register-info-box-record'>
                        <div> Próxima realizada:</div>
                        <div>
                            {nextDid ? monthYear(nextDid) : "-------"}
                        </div>
                    </div>
                    <div>
                        {permission_create &&
                        <div className=' bt-model-4 bt-width-m2' onClick={() => {  setModalNewRecord(true) }}>Novo Registro</div>
                        }
                        </div>
                </div>

            </div>

            <div>





                {modalNewRecord &&
                    <>
                      <FormRecord title={title} handleOnSubmit={submit} closeModal={() => {setModalNewRecord(false)}} /> 
                    </>
                }
            </div>



        </div>
    )
}

export default FormRegisterInfo