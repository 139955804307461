


export function monthToNominal(mes) {
    switch (mes) {
        case "01":
            return "Janeiro"

        case "02":
            return "Fevereiro"

        case "03":
            return "Março"

        case "04":
            return "Abril"

        case "05":
            return "Maio"

        case "06":
            return "Junho"

        case "07":
            return "Julho"

        case "08":
            return "Agosto"

        case "09":
            return "Setembro"

        case "10":
            return "Outubro"

        case "11":
            return "Novembro"

        case "12":
            return "Dezembro"

        default:
            return "erro "
    }

}

export function monthToNominalShort(mes) {
    const month = monthToNominal(mes)

    return month.substring(0, 3)
}

export function monthYear(valor) {
    if (valor) {
        valor = valor.split("-", 2)
        valor[1] = monthToNominalShort(valor[1])

        valor = `${valor[1]}/${valor[0]}`
        return valor
    } else {
        return ""
    }
}

export function ExpiratioTimeCalc(today, expDate) {
    
    let status
    if(today && expDate){
        let aaaaT = today.slice(0, 4)
        let aaaaE = expDate.slice(0, 4)
        let mmT = today.slice(5, 7)
        let mmE = expDate.slice(5, 7)
        if(parseInt(aaaaT) > parseInt(aaaaE)){
            return "vencido"           
        }else{
            if(parseInt(mmT)>= parseInt(mmE)){
                return "vencido"
            }
        }
        return "ok"
    }

}

export function calcMonth(mmaaaa, operation, month) {

    if (mmaaaa) {
        let aaaa = mmaaaa.slice(0, 4)
        let mm = mmaaaa.slice(5, 7)
        if (operation === "+") {

            let newMonth = parseInt(mm) + parseInt(month)
            let newYear = parseInt(aaaa)
            while (newMonth > 12) {
                newMonth = newMonth - 12
                newYear = newYear + 1
            }
            if (newMonth < 10) {
                newMonth = "0" + parseInt(newMonth)

            }
            return `${newYear}-${newMonth}`
        }




    }
    return

}


