import { Link } from 'react-router-dom'
import { useContext } from 'react'
import { useState, useEffect } from 'react'
import api from '../../utils/api'
import './navbar.css'
import logo from '../img/logo.png'

//Hooks
import useMyPermission from '../../hooks/useMyPermission'
/* context */
import { Context } from '../../context/UserContext'

function Navbar() {

  const { authenticated, logout } = useContext(Context)

  const [myPermission, setMyPermission] = useState({ group: "", })
  //CHECK PERMISSIONS
  const { myPermissionTest } = useMyPermission()
  const myPermissionServiceExpEdit = myPermissionTest(myPermission, "serviceExp_Edit")
  const myPermissionServiceExpDelete = myPermissionTest(myPermission, "serviceExp_Delete")
  const myPermissionServiceExpView = myPermissionTest(myPermission, "serviceExp_View")
  const myPermissionServiceExpCreate = myPermissionTest(myPermission, "serviceExp_Create")

  const myPermissionClientEdit = myPermissionTest(myPermission, "client_Edit")
  const myPermissionClientDelete = myPermissionTest(myPermission, "client_Delete")
  const myPermissionClientView = myPermissionTest(myPermission, "client_View")
  const myPermissionClientCreate = myPermissionTest(myPermission, "client_Create")



  const userName = localStorage.getItem('userName')

  useEffect(() => {
    const token = localStorage.getItem('token')
    if(token){
    api.get('/user/get/permission', {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    })
      .then((response) => {
        
        setMyPermission(response.data.permission)

      })
    }
  }, [])






  if (authenticated) {
    return (

      <nav className="navbar">
        <div className='navbar-left-side' >
          <div className='navbar-logo'>
            <img className='navbar-logo-img' src={logo} />
          </div>


          <div className='navbar-options'>

            <Link className='navbar-option-item' to="/"> Início</Link>

            {myPermissionClientView &&
              <div className="dropdown">
                <li className="dropbtn">Clientes</li>
                <div className="dropdown-content">
                  <Link to="/client"> Exibir Clientes</Link>
                  {myPermissionClientCreate &&
                    <Link to="/client/new">Adicionar Cliente</Link>
                  }
                  {myPermissionClientCreate &&
                    <Link to="/region/list">Regiões</Link>
                  }

                </div>

              </div>
            }


            {myPermissionServiceExpView &&
              <Link className='navbar-option-item' to="/horimetro/list"> Serviços e Prazos</Link>
            }

            {(myPermission.group === "DEV_MASTER" || myPermission.group === "ADM_USER") && (
              <div className="dropdown">
                <li className="dropbtn">Usuários</li>
                <div className="dropdown-content">
                  <Link to="/system-user/list"> Exibir Usuários</Link>
                  <Link to="/system-user/new">Adicionar Usuário</Link>
                  <Link to="/Historic/list">Histórico</Link>
                </div>

              </div>
            )
            }
            {/*  <div className="dropdown">
              <li className="dropbtn">Admistrador</li>
              <div className="dropdown-content">
                <Link to="/user/list"> Usuários</Link>

              </div>
            </div> */}








          </div>
        </div>

        <div className='navbar-right-side'>
          {authenticated ? (
            <>
              <li>Olá {userName}</li>
              <li onClick={logout}>Sair</li>
            </>
          ) : (<Link to="/login"> Login</Link>)

          }
        </div>



      </nav>


    )
  } else {
    return (<div></div>)
  }

}

export default Navbar