
import { useState, useEffect } from "react"
import Select from 'react-select'
import { Link } from "react-router-dom"

import "./FormSystemUser.css"
import api from '../../../utils/api'
/* Hooks */
import useFlashMessage from '../../../hooks/useFlashMessage'
/* form */
import Input from "../../../shared/components/form/input"
import Checkbox from "../../../shared/components/form/checkbox"
import FormPassword from "./FormPassword"
import ToggleSwitch from "../../../shared/components/form/ToggleSwitch"

//icon
import { PiCheckCircleFill } from "react-icons/pi";
import { MdRadioButtonUnchecked } from "react-icons/md";

//img
import calendar from "../img/calendar.jpg"
import client from "../img/client.jpg"
const defaultPermission = {
  group:"",
}

function FormSystemUser({ handleSubmit, userData, btnText, btnCancelLink, title, changePassword, permissionData, group}) {
  const [myPermission, setMyPermission] = useState(permissionData || {})
  const [user, setUser] = useState(userData || {})
  const [permission, setPermission] = useState(userData.permission || {})
  const [newPwd, setNewPwd] = useState({})
  const [selectedTab, setSelectedTab] = useState("user")


  const [modalChangePwd, setModalChangePwd] = useState(false)
  const [type, setType] = useState("password")
  const [token] = useState(localStorage.getItem('token') || '')
  const { setFlashMessage } = useFlashMessage()
 
  
  //password validation states
  const [lowerValidated, setLowerValidated] = useState(false)
  const [upperValidated, setUpperValidated] = useState(false)
  const [numberValidated, setNumberValidated] = useState(false)
  const [specialValidated, setSpecialValidated] = useState(false)
  const [lengthValidated, setLengthValidated] = useState(false)

  const handleChangePwd = (e) => {
    let value = e.target.value
    setUser({ ...user, [e.target.name]: e.target.value })
    validatePwd(value)

  }
const handleChangePermission = (e) =>{
  setPermission({ ...permission, [e.target.name]:  e.target.value })
}

  const handleChangeTS = (name, value) => {
    setPermission({ ...permission, [name]: value })
  }
  const handleChangeNewPwd = (e) => {
    let value = e.target.value
    setNewPwd({ ...newPwd, [e.target.name]: e.target.value })
    if(e.target.name !== "confirmPassword"){
      validatePwd(value)
      
    }
  }

  function validatePwd(pwd) {
    //regex
    const lower = new RegExp('(?=.*[a-z])')
    const upper = new RegExp('(?=.*[A-Z])')
    const number = new RegExp('(?=.*[0-9])')
    const special = new RegExp('(?=.*[!@#\$%\^&\*])')
    const length = new RegExp('(?=.{8,})')

    //lowercase validation
    if (lower.test(pwd)) {
      setLowerValidated(true)
    } else {
      setLowerValidated(false)
    }
    //uppercase validation
    if (upper.test(pwd)) {
      setUpperValidated(true)
    } else {
      setUpperValidated(false)
    }
    //uppercase validation
    if (number.test(pwd)) {
      setNumberValidated(true)
    } else {
      setNumberValidated(false)
    }
    //special validation
    if (special.test(pwd)) {
      setSpecialValidated(true)
    } else {
      setSpecialValidated(false)
    }
    //lenght validation
    if (length.test(pwd)) {
      setLengthValidated(true)
    } else {
      setLengthValidated(false)
    }
  }
  useEffect(() => {

  }, [token])

  function handleChange(e) {

    setUser({ ...user, [e.target.name]: e.target.value })


  }
  function handleChangeCheckbox(name, value) {

    setUser({ ...user, [name]: value })


  }

  async function submitChangePwd() {
    let msgType = 'success'



    const data = await api.patch(`/user/changepwd/${user._id}`, newPwd, {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    }).then((response) => {

      setModalChangePwd(false)
      return response.data
    })
      .catch((err) => {
        msgType = 'error'
        return err.response.data
      })

    setFlashMessage(data.message, msgType)

  }


  function submit(e) {
    e.preventDefault()
    let data = { ...user, permission: permission }
    handleSubmit(data)


  }

  return (
    <div className="shared-container-form">
      <div className="shared-center-form">
        <h1 className='shared-title-m1'>{title}</h1>
        <div className="form-client-col-1 form-group">
          <div className="shared-tab-row-1">
            <div className={selectedTab === "user" ? "shared-tab-1 shared-tab-active" : " shared-tab-1 shared-tab-disabled "} onClick={() => setSelectedTab("user")}>
              Usuário
            </div>
            <div className={selectedTab === "permission" ? "shared-tab-1 shared-tab-active" : " shared-tab-1 shared-tab-disabled "} onClick={() => setSelectedTab("permission")}>
              Permissões
            </div>
          </div>
        </div>
        {selectedTab === "user" &&
          <form onSubmit={submit} className="form-box-client ">


            {/* CHECKBOX DISABLE USER */}
            {changePassword !== 'disable'
              ?
              <div className="form-client-col-1 form-group">
                <Checkbox

                  text="Usuário desabilitado"

                  name="disabledUser"

                  handleOnChange={() => handleChangeCheckbox("disabledUser", !user.disabledUser)}
                  check={user.disabledUser || false}
                />

              </div>
              : <div></div>
            }
            <div className="form-client-col-1 form-group">
              <Input

                text="Nome completo"
                type="text"
                name="name"
                placeholder="digite o nome do usuário"
                handleOnChange={handleChange}
                value={user.name || ""}
              />

            </div>
            <div className="form-client-col-1 form-group">
              <Input

                text="E-mail"
                type="email"
                name="email"
                placeholder="digite o nome do usuário"
                handleOnChange={handleChange}
                value={user.email || ""}
              />

            </div>
            <div className="form-client-col-1 form-group">
              <Input

                text="Data de Nascimento"
                type="date"
                name="birth"
                placeholder="digite a data de nascimento"
                handleOnChange={handleChange}
                value={user.birth || ""}
              />

            </div>
            <div className="form-client-col-1 form-group">
              <Input

                text="Telefone whattsapp"
                type="tel"
                name="phone"
                placeholder="digite a data de nascimento"
                handleOnChange={handleChange}
                value={user.phone || ""}
              />

            </div>

            <div className="form-client-col-1 form-group">
              <Input

                text="Usuário"
                type="text"
                name="userName"
                placeholder="digite o usuário de acesso"
                handleOnChange={handleChange}
                value={user.userName || ""}
              />

            </div>

            {/* PASSWORD */}
            {changePassword === 'disable'
              ?
              (
                <>
                  <div className="form-client-col-1 form-group">
                    <Input

                      text="Senha"
                      type="password"
                      name="password"
                      placeholder="digite a senha"
                      handleOnChange={handleChangePwd}
                      value={user.password || ""}
                    />

                  </div >
                  <div className="validation-box form-client-col-1 form-group">
                    <div className={upperValidated ? "validation-item-true" : "validation-item-false"}><div className="validation-item-icon">{upperValidated ? <PiCheckCircleFill /> : <MdRadioButtonUnchecked />}</div>Pelo menos uma letra maiúscula</div>
                    <div className={lowerValidated ? "validation-item-true" : "validation-item-false"}><div className="validation-item-icon">{lowerValidated ? <PiCheckCircleFill /> : <MdRadioButtonUnchecked />}</div>Pelo menos uma letra minúscula</div>
                    <div className={numberValidated ? "validation-item-true" : "validation-item-false"}><div className="validation-item-icon">{numberValidated ? <PiCheckCircleFill /> : <MdRadioButtonUnchecked />}</div>Pelo menos um número</div>
                    <div className={specialValidated ? "validation-item-true" : "validation-item-false"}><div className="validation-item-icon">{specialValidated ? <PiCheckCircleFill /> : <MdRadioButtonUnchecked />}</div>Pelo menos um caractere especial</div>
                    <div className={lengthValidated ? "validation-item-true" : "validation-item-false"}><div className="validation-item-icon">{lengthValidated ? <PiCheckCircleFill /> : <MdRadioButtonUnchecked />}</div>Pelo menos 8 caracteres</div>

                  </div>
                  <div className="form-client-col-1 form-group">
                    <Input

                      text="Confirmar senha"
                      type="password"
                      name="confirmPassword"
                      placeholder="repita a senha"
                      handleOnChange={handleChange}
                      value={user.confirmPassword || ""}
                    />


                  </div>

                </>
              )
              :
              (
                <div className="form-client-col-1 form-group">
                  <div className="bt-model-gray-border bt-model-2 bt-width-m1" onClick={() => setModalChangePwd(true)}> Alterar senha</div>
                </div>
              )

            }
            {myPermission.group === "DEV_MASTER" &&
              <div className="form-client-col-1 form-group">
                <Input

                  text="Grupo"
                  type="text"
                  name="group"
                  placeholder="digite o grupo de acesso"
                  handleOnChange={handleChangePermission}
                  value={permission.group || ""}
                />

              </div>
            }






            <div className="form-client-col-1 form-group">
              <div className="form-group-m1 ">
                <input className="bt-model-add bt-model-1 bt-width-m2" type="submit" value={btnText} />
                <Link className="link-default" to={btnCancelLink}><div className="bt-model-cancel bt-model-2 bt-width-m2">Cancelar</div></Link>
              </div>
            </div>
          </form>
        }

        {selectedTab === "permission" &&
          <div className="form-box-client ">
            <div className="permission-item">
              <div className="permission-title">Clientes</div>
              <div className="permission-title-line"></div>
              <div className="permission-item-box" >
                <img className="permission-img" src={client} />
                <div className="shared-toggle-switch-box-1">
                  <ToggleSwitch
                    text={"Visualizar"}
                    name={"client_View"}
                    value={permission.client_View}
                    handleOnChange={() => handleChangeTS("client_View", !permission.client_View)}
                  />


                  <ToggleSwitch
                    text={"Criar"}
                    name={"client_Create"}
                    value={permission.client_Create}
                    handleOnChange={() => handleChangeTS("client_Create", !permission.client_Create)}
                  />


                  <ToggleSwitch
                    text={"Editar"}
                    name={"client_Edit"}
                    value={permission.client_Edit}
                    handleOnChange={() => handleChangeTS("client_Edit", !permission.client_Edit)}
                  />


                  <ToggleSwitch
                    text={"Excluir"}
                    name={"client_Delete"}
                    value={permission.client_Delete}
                    handleOnChange={() => handleChangeTS("client_Delete", !permission.client_Delete)}
                  />



                </div>

              </div>

            </div>
            <div className="permission-item">
              <div className="permission-title">Serviços e Prazos</div>
              <div className="permission-title-line"></div>
              <div className="permission-item-box" >
                <img className="permission-img" src={calendar} />
                <div className="shared-toggle-switch-box-1">
                  <ToggleSwitch
                    text={"Visualizar"}
                    name={"serviceExp_View"}
                    value={permission.serviceExp_View}
                    handleOnChange={() => handleChangeTS("serviceExp_View", !permission.serviceExp_View)}
                  />


                  <ToggleSwitch
                    text={"Criar"}
                    name={"serviceExp_Create"}
                    value={permission.serviceExp_Create}
                    handleOnChange={() => handleChangeTS("serviceExp_Create", !permission.serviceExp_Create)}
                  />


                  <ToggleSwitch
                    text={"Editar"}
                    name={"serviceExp_Edit"}
                    value={permission.serviceExp_Edit}
                    handleOnChange={() => handleChangeTS("serviceExp_Edit", !permission.serviceExp_Edit)}
                  />


                  <ToggleSwitch
                    text={"Excluir"}
                    name={"serviceExp_Delete"}
                    value={permission.serviceExp_Delete}
                    handleOnChange={() => handleChangeTS("serviceExp_Delete", !permission.serviceExp_Delete)}
                  />


                  <ToggleSwitch
                    text={"Alertas"}
                    name={"serviceExp_Alert"}
                    value={permission.serviceExp_Alert}
                    handleOnChange={() => handleChangeTS("serviceExp_Alert", !permission.serviceExp_Alert)}
                  />
                </div>

              </div>

            </div>
          </div>
        }
      </div>

      <div>
        {modalChangePwd &&



          <div>
            <div className={"modal-background-1 "} >
              <div className="modal-small default-model-2">
                <div className="box-x"> <div onClick={() => { setModalChangePwd(false) }} className="x">X</div></div>

                <div >
                  <div className='default-title-m2'>Digite uma nova senha</div>

                  <div className="form-client-col-1 form-group">
                    <Input

                      text="Senha"
                      type="password"
                      name="password"
                      placeholder="digite a senha"
                      handleOnChange={handleChangeNewPwd}
                      value={newPwd.password || ""}
                    />

                  </div >
                  <div className="validation-box form-client-col-1 form-group">
                    <div className={upperValidated ? "validation-item-true" : "validation-item-false"}><div className="validation-item-icon">{upperValidated ? <PiCheckCircleFill /> : <MdRadioButtonUnchecked />}</div>Pelo menos uma letra maiúscula</div>
                    <div className={lowerValidated ? "validation-item-true" : "validation-item-false"}><div className="validation-item-icon">{lowerValidated ? <PiCheckCircleFill /> : <MdRadioButtonUnchecked />}</div>Pelo menos uma letra minúscula</div>
                    <div className={numberValidated ? "validation-item-true" : "validation-item-false"}><div className="validation-item-icon">{numberValidated ? <PiCheckCircleFill /> : <MdRadioButtonUnchecked />}</div>Pelo menos um número</div>
                    <div className={specialValidated ? "validation-item-true" : "validation-item-false"}><div className="validation-item-icon">{specialValidated ? <PiCheckCircleFill /> : <MdRadioButtonUnchecked />}</div>Pelo menos um caractere especial</div>
                    <div className={lengthValidated ? "validation-item-true" : "validation-item-false"}><div className="validation-item-icon">{lengthValidated ? <PiCheckCircleFill /> : <MdRadioButtonUnchecked />}</div>Pelo menos 8 caracteres</div>

                  </div>
                  <div className="form-client-col-1 form-group">
                    <Input

                      text="Confirmar senha"
                      type="password"
                      name="confirmPassword"
                      placeholder="repita a senha"
                      handleOnChange={handleChangeNewPwd}
                      value={newPwd.confirmPassword || ""}
                    />


                  </div>


                  <div className='bt-model-add bt-model-1 bt-width-m2' onClick={() => { submitChangePwd() }} > Salvar</div>
                </div>

              </div>

            </div >
          </div>


        }

      </div>


    </div>
  )
}

export default FormSystemUser