import api from '../../utils/api'
import { useState, useEffect } from "react"
import { Link } from "react-router-dom"

/* form */
import Input from "../../shared/components/form/input"

function HistoricList(){
    const [historics, setHistorics] = useState([])
    const [myPermission, setMyPermission] = useState({ group:"",})
    const [token] = useState(localStorage.getItem('token') || '')

 /* Search */
 const [search, setSearch] = useState('')
 const searchLowerCase = search.toLowerCase()
 
 const historicSearch = historics.filter((item) => item.name.toLowerCase().includes(searchLowerCase));
 
 function handleChange(e) {
     setSearch(e.target.value)
     

 }
 const teste = (x) => {
   let  y = new Date(x)
   y = y.toLocaleString()
    return y

 }
 useEffect(() => {
    api.get('/historic/getall', {
        headers: {
            Authorization: `Bearer ${JSON.parse(token)}`
        }
    })
        .then((response) => {
            setMyPermission(response.data.permission)
            setHistorics(response.data.historics)
        })
}, [token])

    return (
        myPermission.group === "DEV_MASTER" ||  myPermission.group === "ADM_USER" ? (
            <section className='default-container-m1'>
                <h1 className='default-title-m1'> Histórico do Sistema</h1>
                
                <form >
                    <div className='bt-width-m1'>
                        <Input
    
                            
                            type="text"
                            name="search"
                            placeholder="Buscar"
                            handleOnChange={handleChange}
    
                        />
                    </div>
                </form>
                
                
                <div className='List'>
                    {/* Search */}
                    {search.length > 0 ?
                        historicSearch.map((item) => (
    
                            <div className='ListRow' key={item._id}>
                                <div className='bold'>{item.name}</div>
                               
    
                            </div>
    
                        )) : historics.length > 0 ?
                            historics.map((item) => (
    
                                <div className='ListRow' key={item._id}>
                                    <div className='bold'>{item.name}</div>
                                    <div >
                                       {item.action}
                                       
                                    </div>
                                    
                                    <div> {teste(item.date)}</div>
    
                                </div>
    
                            )) : historics.length === 0 && <p> Não há usuários cadastrados</p>}
    
    
                </div>
    
    
                
            </section>
        ):(
            <div></div>
        )
        )
    
}

export default HistoricList