import api from '../../utils/api'
import { useState, useEffect } from "react"
import { Link } from "react-router-dom"

/* contexts */
import { Context } from '../../context/UserContext'
/* CSS */
/* import './user.css' */
import '../../shared/components/form/inputDefault.css'

/* form */
import Input from "../../shared/components/form/input"

/* Hooks */
import useFlashMessage from '../../hooks/useFlashMessage'

/* Modal */
import ConfirmModal from "../../shared/components/confirmModal/ConfirmModal"

/* deny page */
import DenyAcessPage from "../../shared/denyAccessPage/DenyAcessPage"


function ListUser() {
    const [users, setUsers] = useState([])
    const [myPermission, setMyPermission] = useState({ group:"",})
    const [token] = useState(localStorage.getItem('token') || '')
    const { setFlashMessage } = useFlashMessage()
    const [modalSystemUserDelete, setModalSystemUserDelete] = useState(false)
    const [ itemSystemUserDel, setItemSystemUserDel] = useState({})
   
   
    /* Search */
    const [search, setSearch] = useState('')
    const searchLowerCase = search.toLowerCase()
    const userSearch = users.filter((user) => user.name.toLowerCase().includes(searchLowerCase))
    function handleChange(e) {
        setSearch(e.target.value)
        

    }
    function handleOnSearch(e) {


    }
    

    function handleSubmit(e) {
        e.preventDefault()
        /*  register(user) */
    }


    useEffect(() => {
        api.get('/user/getall', {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`
            }
        })
            .then((response) => {
                setMyPermission(response.data.permission)
                setUsers(response.data.users)
            })
    }, [token])

    async function removeSystemUser(id) {
        let msgType = 'success'
        /*  Modal */
        

        const data = await api.delete(`/user/${id}`, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
            },
        }).then((response) => {
            const updatedUsers = users.filter((user) => user._id != id)
            setUsers(updatedUsers)
            setModalSystemUserDelete(false)
            return response.data
        }).catch((err) => {

            msgType = 'error'
            return err.response.data
        })

        setFlashMessage(data.message, msgType)

    }

    

    return (
       myPermission.group === "DEV_MASTER" ||  myPermission.group === "ADM_USER" ? (
        <section className='default-container-m1'>
            <h1 className='default-title-m1'> Usuários do Sistema</h1>
            <Link className="link-default bt-width-m2" to="/system-user/new"> <div className="bt-model-3 bt-width-m2">Cadastrar</div></Link>
            <form >
                <div className='bt-width-m1'>
                    <Input

                        
                        type="text"
                        name="search"
                        placeholder="Buscar"
                        handleOnChange={handleChange}

                    />
                </div>
            </form>
            
            
            <div className='List'>
                {/* Search */}
                {search.length > 0 ?
                    userSearch.map((user) => (

                        <div className='ListRow' key={user._id}>
                            <div className='bold'>{user.name}</div>
                            <div className='action'>
                                <Link className='' to={`/user/edit/${user._id}`}><button className='bt edit' title='Editar'></button></Link>
                                <button className='bt delete' title='Apagar' onClick={() => { setItemSystemUserDel(user); setModalSystemUserDelete(true) }}></button>

                            </div>

                        </div>

                    )) : users.length > 0 ?
                        users.map((user) => (

                            <div className='ListRow' key={user._id}>
                                <div className='bold'>{user.name}</div>
                                <div className='action'>
                                    <Link className='' to={`/system-user/edit/${user._id}`}><button className='bt edit' title='Editar'></button></Link>
                                    <button className='bt delete' title='Apagar' onClick={() => { setItemSystemUserDel(user); setModalSystemUserDelete(true) }}></button>

                                </div>

                            </div>

                        )) : users.length === 0 && <p> Não há usuários cadastrados</p>}


            </div>

            {modalSystemUserDelete &&
                    <>
                        <div>
                            <div className={"modal-background-1 "} >
                                <div className="modal-small default-model-1">
                                    <div className="box-x"> <div onClick={() => { setModalSystemUserDelete(false) }} className="x">X</div></div>

                                    <div >
                                        <div className='default-title-m3'> Deseja realmente apagar esse usuário ?</div>


                                        
                                       
                                    </div>
                                    <div >{itemSystemUserDel.name} </div>

                                    <div className="bt-box-model-flex-1">
                                            <div className='bt-model-delete bt-model-1 bt-width-m2' onClick={() => {removeSystemUser(itemSystemUserDel._id) }} >Apagar </div>
                                            <div className='bt-model-cancel bt-model-1 bt-width-m2' onClick={() => {setModalSystemUserDelete(false) }} >Cancelar </div>
                                        </div>

                                </div>

                            </div >
                        </div>
                    </>}
            
        </section>
    ):(
        <div></div>
    )
    )
}

export default ListUser