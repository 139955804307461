import { useState, useEffect } from "react"
import {Link} from "react-router-dom"
//css
import './modalFormServiceExpiration.css'

import FormRegisterInfo from "./FormRegisterInfo"
import api from '../../../../utils/api'
/* Hooks */
import useFlashMessage from '../../../../hooks/useFlashMessage'
import FormRegisterHorimetro from "./FormRegisterHorimetro"
import useMyPermission from '../../../../hooks/useMyPermission'

function ModalFormServiceExpiration({ updateData, clientData, listClient, closeModal , myPermission}) {

    const [token] = useState(localStorage.getItem('token') || '')
    
    const { setFlashMessage } = useFlashMessage()
    
    //CHECK PERMISSIONS
    const { myPermissionTest } = useMyPermission()
    const myPermissionCreate = myPermissionTest(myPermission, "serviceExp_Create")


   
    let clientInfo = {}
    function updateInfo() {
        let filter = listClient.filter((i) => i._id === clientData._id)
        clientInfo = filter[0]


    }

    function reflesh(){
        updateData()
    }
    async function registerRadiatorRecordDate(info) {
        let msgType = 'success'
        let obj = {
            ...info,
            client: clientData._id
        }
        const data = await api.post('/radiator/new', obj, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`
                }
        }).then((response) => {

            updateData()
            return response.data
        })
            .catch((err) => {
                msgType = 'error'
                return err.response.data
            })
        setFlashMessage(data.message, msgType)
        return msgType
    }

    async function registerInjectionPumpRecordDate(info) {
        let msgType = 'success'
        let obj = {
            ...info,
            client: clientData._id
        }
        
        const data = await api.post('/injectionPump/new', obj, {
            headers: {
            Authorization: `Bearer ${JSON.parse(token)}`
            }
        }).then((response) => {

            updateData()
            return response.data
        })
            .catch((err) => {
                msgType = 'error'
                return err.response.data
            })
        setFlashMessage(data.message, msgType)
        return msgType
    }
    async function registerTankCleaningRecordDate(info) {
        let msgType = 'success'
        let obj = {
            ...info,
            client: clientData._id
        }
        const data = await api.post('/tankCleaning/new', obj, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`
                }
        }).then((response) => {

            updateData()
            return response.data
        })
            .catch((err) => {
                msgType = 'error'
                return err.response.data
            })
        setFlashMessage(data.message, msgType)
        return msgType
    }
    async function registerChangeBatteryRecordDate(info) {
        let msgType = 'success'
        let obj = {
            ...info,
            client: clientData._id
        }
        const data = await api.post('/changeBattery/new', obj, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`
                }
        }).then((response) => {

            updateData()
            return response.data
        })
            .catch((err) => {
                msgType = 'error'
                return err.response.data
            })
        setFlashMessage(data.message, msgType)
        return msgType
    }

    const registerHorimetroRecord = async (info) => {
        let msgType = 'success'
        let obj = {
            ...info,
            client: clientData._id
        }
        
        const data = await api.post('/horimetro/new', obj, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`
                }
        }).then((response) => {

            updateData()
            return response.data
        })
            .catch((err) => {
                msgType = 'error'
                return err.response.data
            })
        setFlashMessage(data.message, msgType)
        return msgType
    }


   

    updateInfo()
    return (

        <div>
            <div className={"modal-background-1 "} >
                <div className="modal-small default-model-big">
                    <div className="box-x"> <div onClick={() => { closeModal() }} className="x">X</div></div>
                    <div className="modal-form-service-expiration-container">
                        <label className="default-title-m5">{clientData.clientName}</label>
                        <div className="modal-form-service-expiration-options">
                        <Link to={`/horimetro/edit/${clientData._id}`} target="_blank"><div className="bt-model-3 bt-width-m2">Detalhes</div></Link>
                        <div className="bt-model-3 bt-width-m2" onClick={()=> {reflesh()}}> Atualizar </div>
                        </div>
                        <div className="modal-form-service-expiration-list">
                            



                            <FormRegisterInfo title={"Radiador Sist. Arr.:"} lastDid={clientInfo.lastInspectionRadiatorDate} nextDid={clientInfo.nextInspectionRadiatorDate} handleOnSubmit={registerRadiatorRecordDate} permission_create={myPermissionCreate} />
                            <FormRegisterInfo title={"Revião de Bomba Injetora:"} lastDid={clientInfo.lastInspectionInjectionPumpDate} nextDid={clientInfo.nextInspectionInjectionPumpDate} handleOnSubmit={registerInjectionPumpRecordDate} permission_create={myPermissionCreate} />
                            <FormRegisterInfo title={"Limpeza de Tanque:"} lastDid={clientInfo.lastTankCleaningDate} nextDid={clientInfo.nextTankCleaningDate} handleOnSubmit={registerTankCleaningRecordDate} permission_create={myPermissionCreate} />
                            <FormRegisterInfo title={"Troca de Bateria:"} lastDid={clientInfo.lastChangeBatteryDate} nextDid={clientInfo.nextChangeBatteryDate} handleOnSubmit={registerChangeBatteryRecordDate} permission_create={myPermissionCreate} />
                            <FormRegisterHorimetro
                                lastHorimetroDate={clientInfo.lastHorimetroDate}
                                lastHorimetroRecord={clientInfo.lastHorimetroRecordSeconds}
                                lastHorimetroChangeOilAndFilterDate={clientInfo.lastHorimetroChangeOilAndFilterDate}
                                lastHorimetroChangeOilAndFilterRecord={clientInfo.lastHorimetroChangeOilAndFilterRecordSeconds}
                                nextHorimetroChangeOilAndFilterDate={clientInfo.nextHorimetroChangeOilAndFilterDate}
                                totalLastHorimetroChangeOilAndFilterRecord={clientInfo.totalLastHorimetroChangeOilAndFilterRecordSeconds}
                                nextHorimetroDate={clientInfo.nextHorimetroDate}
                                handleOnSubmit={registerHorimetroRecord}
                                permission_create={myPermissionCreate}
                                
                            />
                            {/*    <div className='bt-model-add bt-model-1 bt-width-m2' onClick={() => { handleOnSubmit(newRecord) }} > Salvar registro</div> */}
                        </div>
                    </div>
                </div>

            </div >
        </div>

    )

}
export default ModalFormServiceExpiration