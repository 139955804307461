import { useState } from 'react'
import './input.css'

//icon
import { FaEye, FaEyeSlash } from "react-icons/fa";
function Input({
    styles,
    type,
    text,
    name,
    placeholder,
    handleOnChange,
    value,
    multiple,

    pattern,
    status,

}) {

    const [inputType, setInputType] = useState(type || "text")
    

    return (

        <div >
            {text &&
                <label htmlFor={name}>{text}:</label>
            }
            <div className='input-box'>
                <input className="input"

                    type={type === "password" ? inputType : type}

                    name={name} id={name} placeholder={placeholder}
                    onChange={handleOnChange}
                    value={value}

                    {...(multiple ? { multiple } : '')}
                />
                
                {type === "password"
                    ?
                    inputType === "password"
                        ?
                        <div className='pwd-eye-box' onClick={() => setInputType("text")}>
                            <FaEyeSlash />
                        </div>
                        :
                        <div className='pwd-eye-box' onClick={() => setInputType("password")}>
                            <FaEye />
                        </div>
                    :
                    <div>

                    </div>

                }
            </div>
        </div>

    )
}
export default Input
