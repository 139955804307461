import { useState } from 'react'
import Input from '../../../../shared/components/form/input'

import './formRegisterHorimetro.css'
/* Function */
import { monthYear, secondToHour, secondToMinute, secondToSecond } from "../../../../shared/functions/Date"
import FormRecordHorimetro from './FormRecordHorimetro'


function FormRegisterHorimetro({ permission_create, inputName, lastDid, lastHorimetroDate, lastHorimetroRecord, lastHorimetroChangeOilAndFilterDate, lastHorimetroChangeOilAndFilterRecord, lastDidOilAndFilterRecord, nextHorimetroChangeOilAndFilterDate, totalLastHorimetroChangeOilAndFilterRecord, nextHorimetroDate, handleOnSubmit }) {



    const [modalNewRecord, setModalNewRecord] = useState(false)
    const [newDate, setNewDate] = useState("")
    const [newRecord, setNewRecord] = useState({})
    const [changeOilAndFilter, setChangeOilAndFilter] = useState()


    
    function handleChange(e) {
        setNewRecord({ ...newRecord, [e.target.name]: e.target.value })
    }
    function handleChangeDate(e) {
        setNewDate(e.target.value)
    }

    async function submit(data) {
        let obj = {
            ...data,
            changeOilAndFilter: changeOilAndFilter,

        }
        let response = await handleOnSubmit(obj)
        if (response === "success") {
            setModalNewRecord(false)

        }
    }

    return (
        <div>
            <div className='form-register-info-box'>
                <label> Troca de Óleo e Filtro</label>
                <div className='form-register-info-box-box'>
                    <div>
                        <div className='form-register-info-box-record'>
                            <div> Última realizada: </div>
                            <div>
                                {lastHorimetroChangeOilAndFilterDate ? monthYear(lastHorimetroChangeOilAndFilterDate) : "-------"}
                            </div>
                        </div>
                        <div className='form-register-info-box-record'>
                            <div> Horímetro da Última troca: </div>
                            <div>
                                {lastHorimetroChangeOilAndFilterRecord ? `${secondToHour(lastHorimetroChangeOilAndFilterRecord)}h ${secondToMinute(lastHorimetroChangeOilAndFilterRecord)}min ${secondToSecond(lastHorimetroChangeOilAndFilterRecord)}s` : "-------"}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='form-register-info-box-record'>
                            <div> Próxima a realizar: </div>
                            <div>
                                {nextHorimetroChangeOilAndFilterDate ? monthYear(nextHorimetroChangeOilAndFilterDate) : "-------"}
                            </div>
                        </div>
                        <div className='form-register-info-box-record'>
                            <div> Total Rodado desde a Última troca: </div>
                            <div>
                                {totalLastHorimetroChangeOilAndFilterRecord ? `${secondToHour(totalLastHorimetroChangeOilAndFilterRecord)}h ${secondToMinute(totalLastHorimetroChangeOilAndFilterRecord)}min ${secondToSecond(totalLastHorimetroChangeOilAndFilterRecord)}s` : "-------"}
                            </div>
                        </div>
                    </div>
                    <div>
                        {permission_create &&
                            <div className=' bt-model-4 bt-width-m2' onClick={() => { setChangeOilAndFilter("yes"); setModalNewRecord(true) }}>Novo Registro</div>
                        }
                    </div>
                </div>



            </div>
            <div className='form-register-info-box'>
                <label>Horímetro</label>
                <div className='form-register-info-box-box'>
                    <div>
                        <div className='form-register-info-box-record'>
                            <div> Data do último registro: </div>
                            <div>
                                {lastHorimetroDate ? monthYear(lastHorimetroDate) : "-------"}
                            </div>
                        </div>
                        <div className='form-register-info-box-record'>
                            <div> Último registro: </div>
                            <div>
                                {lastHorimetroRecord ? `${secondToHour(lastHorimetroRecord)}h ${secondToMinute(lastHorimetroRecord)}min ${secondToSecond(lastHorimetroRecord)}s` : "-------"}
                            </div>
                        </div>
                    </div>

                    <div>
                        {permission_create &&
                            <div className=' bt-model-4 bt-width-m2' onClick={() => { setChangeOilAndFilter("no"); setModalNewRecord(true); }}>Novo Registro</div>
                        }
                    </div>
                </div>

            </div>


            <div>

                {modalNewRecord &&
                    <>

                        <FormRecordHorimetro handleOnSubmit={submit} closeModal={() => { setModalNewRecord(false) }} changeOilAndFilter={changeOilAndFilter} />
                    </>

                }

                {/* 
                {modalNewRecord &&
                    <div>
                        <div className={"modal-background-1 "} >
                            <div className="modal-small default-model-1">
                                <div className="box-x"> <div onClick={() => { setModalNewRecord(false) }} className="x">X</div></div>

                                <div >
                                    <div className='default-title-m2'> {changeOilAndFilter === "yes" ? "Troca de Óleo e Filtro" : "Registrar Horímetro"} </div>
                                    <Input
                                        type="month"
                                        name="horimetroDate"
                                        text="Mês e Ano"
                                        handleOnChange={handleChange}
                                        value={newRecord.horimetroDate || ""}
                                    />
                                    <Input
                                        type="number"
                                        name="horimetroRecord"
                                        text={changeOilAndFilter === "yes" ? "Horimetro da Troca" : "Horímetro"}
                                        handleOnChange={handleChange}
                                        value={newRecord.horimetroRecord || ""}
                                    />



                                    <div className='bt-model-add bt-model-1 bt-width-m2' onClick={() => { submit() }} > Salvar registro</div>
                                </div>

                            </div>

                        </div >
                    </div>
                } */}
            </div>



        </div>
    )
}

export default FormRegisterHorimetro