import api from '../../../../utils/api'
import { useState, useEffect } from "react"

import './SideBar.css'
//icon
import { TbArrowBigLeftLinesFilled ,TbArrowBigRightLinesFilled } from "react-icons/tb";
import { HiChevronLeft } from "react-icons/hi";
import { HiChevronDoubleLeft, HiChevronDoubleRight } from "react-icons/hi";

//imagens
import iconConfig from '../../img/iconConfiguracao.png'
import iconMaterial from '../../img/iconMaterialBest.png'
import iconEmergencia from '../../img/iconEmergencia.png'
import iconHorario from '../../img/iconHorario.png'
import iconManuValida from '../../img/iconManuValida.png'
import iconManuVencida from '../../img/iconManuVencida.png'

function SideBar({ filter, selectFilter }) {
    let startSBStatus = true
    let localStorageSB = localStorage.getItem('sideBarStatus')
    if(localStorageSB){
        startSBStatus = localStorageSB === "true"
    }else{
        startSBStatus = true
    }
   
    const [token] = useState(localStorage.getItem('token') || '')
    const [sideBarStatus, setsideBarStatus] = useState(startSBStatus)
    /* Modal topo*/
    const [modal, setModal] = useState(false)
    const [region, setRegion] = useState([])
    const [id, setId] = useState()
    const [name, setName] = useState('')

    const showSideBar = () => {setsideBarStatus(!sideBarStatus); localStorage.setItem('sideBarStatus', !sideBarStatus ) }

    useEffect(() => {
        getAllRegion()
    }, [token])

    async function getAllRegion() {
        api.get('/region/getall', {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`
            }
        })
            .then((response) => {
                setRegion(response.data.regions)
            })
    }


    return (
        <>
        {sideBarStatus 
        ? <div className="box-side-bar">

            <div className='box-side-bar-box-arrow'>
                <div className='box-side-bar-box-arrow-button arrow-to-left' onClick={()=> {showSideBar()}}>
                <HiChevronDoubleLeft /><HiChevronDoubleLeft /> 
                </div>
            </div>
            <div className="box-region fade-in-transition">
                <div className="box-subtitle-title box-subtitle">
                    {/* <p className="icon-space"></p> */}
                    <p>Região</p>

                    {/* <p><img className="icon-space icon" src={iconConfig} alt="" onClick={()=>{handleOnclick("1","teste")}}/></p> */}
                </div>
                <div className="box-region-option">
                    <p className={selectFilter === "" ? ("p-region p-region-filter-selected") : ("p-region")} onClick={() => { filter("") }} >Todos</p>
                    {
                        region.map((item) => (
                            <p className={selectFilter === item._id ? ("p-region p-region-filter-selected") : ("p-region")} onClick={() => { filter(item._id) }} key={item._id}>{item.regionName}</p>
                        ))
                    }
                </div>


            </div>
            <div className="box-subtitle fade-in-transition">
                <div className="box-subtitle-title">

                    <p>Legenda</p>

                </div>
                <div>
                    <div className="box-subtitle-option">
                        <img className="subtitle-icon" src={iconManuVencida} alt="" />
                        <p>Manutenção Vencida</p>
                    </div>
                    <div className="box-subtitle-option">
                        <img className="subtitle-icon" src={iconManuValida} alt="" />
                        <p>Manutenção Valida</p>
                    </div>
                    <div className="box-subtitle-option">
                        <img className="subtitle-icon" src={iconMaterial} alt="" />
                        <p>Cliente com material de revisão fornecido pela Bestforce</p>
                    </div>
                    <div className="box-subtitle-option">
                        <img className="subtitle-icon" src={iconEmergencia} alt="" />
                        <p>Emergência</p>
                    </div>
                    <div className="box-subtitle-option">
                        <img className="subtitle-icon" src={iconHorario} alt="" />
                        <p>Horário de ponta</p>
                    </div>

                </div>

            </div>


        </div>
        :<div className="box-side-bar-close">

        <div className='box-side-bar-box-arrow'>
            <div className='box-side-bar-box-arrow-button arrow-to-right' onClick={()=> {showSideBar()}}>
            <HiChevronDoubleRight /><HiChevronDoubleRight /> 
            </div>
        </div>
        
       


    </div>
        
}
        </>

    )
}

export default SideBar