import { useState, useEffect } from 'react'

import bus from '../../../utils/bus'

import './message.css'

function Message() {
    const [visibility, setVisibility] = useState(false)
    const [message, setMessage] = useState('')
    
    const [type, setType] = useState('')

    useEffect(() => {

        bus.addListener('flash', ({message, type}) => {
            setVisibility(true)
            setMessage(message)
            setType(type)
            
            setTimeout(() => {
                setVisibility(false)
            }, 8000)
            
        })
    }, [])
    
    return(
        visibility && (
        <div className = {`message ${type}`}>
            <div className='message-box'>
               
            <div className="message-box-x"> <div className='message-box-text'>{message}</div><div onClick={() => { setVisibility(false) }} className="message-x">X</div></div>
            </div>
            
            
            </div>
        )
    )
}

export default Message