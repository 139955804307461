import api from '../../utils/api'

import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Navigate, useNavigate } from 'react-router-dom'




/* Hooks */
import useFlashMessage from '../../hooks/useFlashMessage'
import FormSystemUser from './components/FormSystemUser'
import DenyAcessPage from '../../shared/denyAccessPage/DenyAcessPage'

function EditUser(){
    const [user, setUser] = useState({})
    const [myPermission, setMyPermission] = useState({})
    const [token] = useState(localStorage.getItem('token') || '')
    const {id} = useParams()
    const {setFlashMessage} = useFlashMessage()
    const navigate = useNavigate()

    useEffect(() => {

        api.get(`/user/${id}`, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
            },
            
        }).then((response) => {
            
            setMyPermission(response.data.permission)
            setUser(response.data.user)
            
        })
        
    }, [token, id])

    async function updateUser(user) {
        let msgType = 'success'
        
        
        const data = await api.patch(`user/edit/${user._id}`, user, {
            headers: {
            Authorization: `Bearer ${JSON.parse(token)}`,
        },
        }).then((response) => {
            
            return response.data
        }).catch((err) => {
            
            msgType= 'error'
            return err.response.data
        })
        setFlashMessage(data.message, msgType)
        if (msgType !== 'error'){
            
            navigate('/system-user/list')
            }

    }


    return (
        myPermission.group === "DEV_MASTER" ||  myPermission.group === "ADM_USER" ? (
        <section className='shared-container-m1'>
            {user.name  && (

                
                <FormSystemUser handleSubmit={updateUser} title={"Editar Usuário do sistema"} btnText = "Atualizar" btnCancelLink = "/system-user/list" userData={user} permissionData={myPermission}/>
                
                    
                
            )
        }
        </section>
        ):(
        <div></div>
        )
    )
}

export default EditUser