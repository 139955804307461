import api from '../../utils/api'
import { useState, useEffect } from "react"
import { Link } from "react-router-dom"

/* CSS */
import './client.css'
import '../../shared/components/form/inputDefault.css'

/* form */
import Input from "../../shared/components/form/input"

/* Hooks */
import useFlashMessage from '../../hooks/useFlashMessage'
import useMyPermission from '../../hooks/useMyPermission'

/* Modal */
import ConfirmModal from "../../shared/components/confirmModal/ConfirmModal"


function ListClient() {

    const [myPermission, setMyPermission] = useState({ group: "", })
    const [clients, setClients] = useState([])
    const [token] = useState(localStorage.getItem('token') || '')
    const { setFlashMessage } = useFlashMessage()

    //CHECK PERMISSIONS
    const { myPermissionTest } = useMyPermission()
    const myPermissionEdit = myPermissionTest(myPermission, "client_Edit")
    const myPermissionDelete = myPermissionTest(myPermission, "client_Delete")
    const myPermissionView = myPermissionTest(myPermission, "client_View")
    const myPermissionCreate = myPermissionTest(myPermission, "client_Create")

    /* Modal topo*/
    const [modal, setModal] = useState(false)
    const [id, setId] = useState()
    const [name, setName] = useState('')
    const handleOnclick = (id, name) => {
        setModal(true)
        setId(id)
        setName(name)


    }
    /* Search */
    const [search, setSearch] = useState('')
    const searchLowerCase = search.toLowerCase()
    const clientSearch = clients.filter((client) => client.clientName.toLowerCase().includes(searchLowerCase))
    function handleChange(e) {
        setSearch(e.target.value)
      

    }
    function handleOnSearch(e) {


    }

    function handleSubmit(e) {
        e.preventDefault()
        /*  register(user) */
    }





    useEffect(() => {
        api.get('/client/getall', {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`
            }
        })
            .then((response) => {
                setMyPermission(response.data.permission)
                setClients(response.data.clients)

            })
    }, [token])

    async function removeClient(id) {
        let msgType = 'success'
        /*  Modal */
        setModal(false)

        const data = await api.delete(`/client/${id}`, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
            },
        }).then((response) => {
            const updatedClients = clients.filter((client) => client._id != id)
            setClients(updatedClients)
            return response.data
        }).catch((err) => {

            msgType = 'error'
            return err.response.data
        })

        setFlashMessage(data.message, msgType)

    }



    return (
        myPermissionView ? (
            <section className='default-container-m1'>
                <h1 className='default-title-m1'> Clientes</h1>
                {myPermissionCreate &&
                    <Link className="link-default bt-width-m2" to="/client/new"> <div className="bt-model-3 bt-width-m2">Cadastrar</div></Link>
                }
                <form >
                    <div className='bt-width-m1'>
                        <Input


                            type="text"
                            name="search"
                            placeholder="Buscar"
                            handleOnChange={handleChange}

                        />
                    </div>
                </form>


                <div className='List'>
                    {/* Search */}
                    {search.length > 0 ?
                        clientSearch.map((client) => (

                            <div className='ListRow' key={client._id}>
                                <div className='bold'>{client.clientName}</div>
                                <div className='action'>
                                    <Link className='' to={`/client/edit/${client._id}`}><button className='bt edit' title='Editar'></button></Link>

                                    <button className='bt delete' title='Apagar' onClick={() => { handleOnclick(client._id, client.clientName) }}></button>

                                </div>

                            </div>

                        )) : clients.length > 0 ?
                            clients.map((client) => (

                                <div className='ListRow' key={client._id}>
                                    <div className='bold'>{client.clientName}</div>
                                    <div className='action'>
                                        {myPermissionEdit &&
                                            <Link className='' to={`/client/edit/${client._id}`}><button className='bt edit' title='Editar'></button></Link>
                                        }
                                        {myPermissionDelete &&
                                            <button className='bt delete' title='Apagar' onClick={() => { handleOnclick(client._id, client.clientName) }}></button>
                                        }
                                    </div>

                                </div>

                            )) : clients.length === 0 && <p> Não há clientes cadastrados</p>}


                </div>


                {/* Modal */}
                <ConfirmModal
                    openModal={modal}
                    closeModal={() => setModal(!modal)}
                    confirm={removeClient}
                    id={id}
                    messageModal={'Deseja realmente excluir esse cliente ? '}
                    name={name}
                    btConfirmStyle={'btDelete'}
                    btConfirmValue={'Apagar'}
                />
            </section>
        ) : (
            <div></div>
        )
    )
}

export default ListClient