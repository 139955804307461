

function Home() {
    const lastTime = '2024-02'
    const expirationTime = 8
    const horimetroByClient = [
        {
          _id: '661e9e0e186d3d6cb849e58c',
          horimetroDate: '2024-07',
          horimetroRecord: '20',
          changeOilAndFilter: false,
          client: '65f710ea7ecc68a0e25216a5',
          
          __v: 0
        },
        {
          _id: '661ea2b6be74e9d6642faa47',
          horimetroDate: '2024-07',
          horimetroRecord: '30',
          changeOilAndFilter: false,
          client: '65f710ea7ecc68a0e25216a5',
          
          __v: 0
        },
        {
          _id: '661e9db153bbe53dd02ed02a',
          horimetroDate: '2024-07',
          horimetroRecord: '10',
          changeOilAndFilter: false,
          client: '65f710ea7ecc68a0e25216a5',
         
          __v: 0
        },
        {
          _id: '661e9d60a2285fa12e46a6df',
          horimetroDate: '2024-07',
          horimetroRecord: '21',
          changeOilAndFilter: false,
          client: '65f710ea7ecc68a0e25216a5',
          
          __v: 0
        },
        {
          _id: '661e9d6ca2285fa12e46a6e4',
          horimetroDate: '2024-05',
          horimetroRecord: '21',
          changeOilAndFilter: false,
          client: '65f710ea7ecc68a0e25216a5',
          
          __v: 0
        },
        {
          _id: '661e9e4e204cc01a17e6dc38',
          horimetroDate: '2024-05',
          horimetroRecord: '10',
          changeOilAndFilter: false,
          client: '65f710ea7ecc68a0e25216a5',
          
          __v: 0
        },
        {
          _id: '661e9d41a2285fa12e46a6d5',
          horimetroDate: '2024-04',
          horimetroRecord: '31',
          changeOilAndFilter: false,
          client: '65f710ea7ecc68a0e25216a5',
          
          __v: 0
        },
        {
          _id: '661ea27fc04118744d3f6f2b',
          horimetroDate: '2024-04',
          horimetroRecord: '10',
          changeOilAndFilter: false,
          client: '65f710ea7ecc68a0e25216a5',
          
          __v: 0
        },
        {
          _id: '661e9d50a2285fa12e46a6da',
          horimetroDate: '2024-03',
          horimetroRecord: '30',
          changeOilAndFilter: false,
          client: '65f710ea7ecc68a0e25216a5',
          
          __v: 0
        },
        {
          _id: '661eb9aec644ff48c7c76355',
          horimetroDate: '2024-03',
          horimetroRecord: '10',
          changeOilAndFilter: false,
          client: '65f710ea7ecc68a0e25216a5',
          
          __v: 0
        },
        {
          _id: '660352b38c02ef4120b2f645',
          horimetroDate: '2024-02',
          horimetroRecord: '30',
          changeOilAndFilter: false,
          client: '65f710ea7ecc68a0e25216a5',
         
          __v: 0
        },
        {
          _id: '660352817930a72ce817ffd2',
          horimetroDate: '2024-02',
          horimetroRecord: '20',
          changeOilAndFilter: false,
          client: '65f710ea7ecc68a0e25216a5',
          
          __v: 0
        },
        {
          _id: '660352707930a72ce817ffcd',
          horimetroDate: '2024-01',
          horimetroRecord: '10',
          changeOilAndFilter: true,
          client: '65f710ea7ecc68a0e25216a5',
          
          __v: 0
        }
      ]

    function calcMonth() {

        if (lastTime) {
            let aaaa = lastTime.slice(0, 4)
            let mm = lastTime.slice(5, 7)

            let newMonth = parseInt(mm) + parseInt(expirationTime)
            let newYear = parseInt(aaaa)
            while (newMonth > 12) {
                newMonth = newMonth - 12
                newYear = newYear + 1
            }

            
            return (<div>{` mes ${mm} e ano ${aaaa} `}
                <p>proximo {` mes ${newMonth} e ano ${newYear} `}</p>


            </div>)

        }
    }
    let teste1 = horimetroByClient.sort((a,b)=> new Date(b.horimetroDate).getTime() - new Date(a.horimetroDate).getTime())
   
    if( teste1){
        let filterDate = teste1[0].horimetroDate
        let teste2 = teste1.filter((item)=> item.horimetroDate === filterDate)
      
        teste2 = teste2.sort((a,b)=> b.horimetroRecord - a.horimetroRecord)
        
    }

    return (
        <section className='default-container-m1'>
            <p>Pagina Inicial em construção</p>
            <p>Use o menu superior para navegar</p>

            
        </section>
    )
}

export default Home