export default function useMyPermission(){

    function myPermissionTest(arrayPermission, nome){
        if(arrayPermission){
            if(arrayPermission.group === "DEV_MASTER"){
                return true
            }
            if(arrayPermission.group === "ADM_USER"){
                return true
            }
            if(arrayPermission[nome] === true){
                return true
    
            }else{
                return false
            }
        }
        return false

    }
    return {myPermissionTest}
   
}