import api from '../../utils/api'

import FormSystemUser from './components/FormSystemUser'
import './newSystemUser.css'
import { useContext, useEffect, useState } from 'react'
/* contexts */
import { Context } from '../../context/UserContext'

import DenyAcessPage from '../../shared/denyAccessPage/DenyAcessPage'

function NewSystemUser() {
    const { register } = useContext(Context)
    const [user, setUser] = useState({})
    const [myPermission, setMyPermission] = useState({group:"",  })
    const [token] = useState(localStorage.getItem('token') || '')
    const handleRegister = (user) => {

        register(user)
    }
    useEffect(() => {
        api.get('/user/get/permission', {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`
            }
        })
            .then((response) => {
                setMyPermission(response.data.permission)
                setUser(response.data.user)
            })
    }, [])
    return (



        <div className='shared-container-m1'>
            {user.name &&

                myPermission.group === "DEV_MASTER" || myPermission.group === "ADM_USER" ? (
                <FormSystemUser permissionData={myPermission} userData={{permission: {}}} title={'Novo Usuário do Sistema'} changePassword={'disable'} handleSubmit={handleRegister} />
                ):(
                   <div></div>
                )
            
            }
        </div>

    )
}

export default NewSystemUser