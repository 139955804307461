import api from '../../utils/api'

import { useState, useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'



/* Hooks */
import useFlashMessage from '../../hooks/useFlashMessage'
import useMyPermission from '../../hooks/useMyPermission'

import FormClient from './components/FormClient'

function NewClient() {
    const [token] = useState(localStorage.getItem('token') || '')
    const { setFlashMessage } = useFlashMessage()
    const navigate = useNavigate()
    const [myPermission, setMyPermission] = useState({ group: "", })

    //CHECK PERMISSIONS
    const { myPermissionTest } = useMyPermission()
    const myPermissionCreate = myPermissionTest(myPermission, "client_Create")

    useEffect(() => {

        api.get(`/user/get/permission`, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
            },

        }).then((response) => {

            setMyPermission(response.data.permission)


        })

    }, [token])

    async function registerClient(client) {
        let msgType = 'success'

        const data = await api.post('/client/new', client, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`
            }
        }).then((response) => {
            return response.data
        })
            .catch((err) => {
                msgType = 'error'
                return err.response.data
            })

        setFlashMessage(data.message, msgType)

        if (msgType !== 'error') {

            navigate('/client')
        }
    }


    return (

        myPermissionCreate ? (
            <div className='shared-container-m1'>
                <FormClient title={'Novo Cliente'} handleSubmit={registerClient} btnText="Cadastrar" />
            </div>
        ) : (
            <div></div>
        )

    )
}

export default NewClient