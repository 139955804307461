import { useState } from 'react'
import Input from '../../../../shared/components/form/input'
import Checkbox from "../../../../shared/components/form/checkbox"
import { secondToHour, secondToMinute, secondToSecond } from "../../../../shared/functions/Date"

function FormRecord({ title, data, closeModal, handleOnSubmit, changeOilAndFilter }) {
   
    let hour
    let minute
    let second
    let dataTime
    if (data) {
        hour = secondToHour(data.horimetroRecordSeconds)
        minute = secondToMinute(data.horimetroRecordSeconds)
        second = secondToSecond(data.horimetroRecordSeconds)
        dataTime = {
            horimetroHour: hour,
            horimetroMinute: minute,
            horimetroSecond: second,
        }
       
    }

    const [newRecord, setNewRecord] = useState(data || {})
    const [time, setTime] = useState(dataTime || {})
    

   
    function handleChange(e) {
        setNewRecord({ ...newRecord, [e.target.name]: e.target.value })
    }
    function handleChangeHour(e) {
        setTime({ ...time, [e.target.name]: e.target.value })
    }
    function handleChange60(e) {
        if (e.target.value < 60 && e.target.value.length <= 2) {

            setTime({ ...time, [e.target.name]: e.target.value })
        }
        

    }
    function handleChangeCheckbox(name, value) {

        setNewRecord({ ...newRecord, [name]: value })


    }
    async function submit() {
        let data = newRecord
        let seconds = 0
        if (time.horimetroHour) {
            seconds = (parseInt(time.horimetroHour) * 60) * 60
            
        }
        if (time.horimetroMinute) {
            seconds = seconds + (parseInt(time.horimetroMinute) * 60)
            
        }
        if (time.horimetroSecond) {
            seconds = seconds + parseInt(time.horimetroSecond)
            
        }
        if (seconds !== 0) {
            data.horimetroRecordSeconds = seconds
           
        }


        handleOnSubmit(data)
    }




    return (

        <div>

            <div className={"modal-background-1 "} >
                <div className="modal-small default-model-1">
                    <div className="box-x"> <div onClick={() => { closeModal() }} className="x">X</div></div>

                    <div >
                        <div className='default-title-m2'> {changeOilAndFilter === "yes" ? "Troca de Óleo e Filtro" : "Registrar Horímetro"} </div>
                        <Input
                            type="date"
                            name="horimetroDate"
                            text="Data"
                            handleOnChange={handleChange}
                            value={newRecord.horimetroDate || ""}
                        />
                        <label>{changeOilAndFilter === "yes" ? "Horimetro da Troca:" : "Horímetro:"}</label>
                        <div className='horimetro-input'>
                            <div className='horimetro-input-div'>

                                <Input
                                    type="number"
                                    name="horimetroHour"

                                    handleOnChange={handleChangeHour}
                                    value={time.horimetroHour || ""}
                                />

                            </div>
                            <div className='horimetro-input-text'> h </div>
                            <div className='horimetro-input-div-60'>
                                <Input
                                    type="number"
                                    name="horimetroMinute"

                                    handleOnChange={handleChange60}
                                    value={time.horimetroMinute || ""}
                                />
                            </div>
                            <div className='horimetro-input-text'> min </div>
                            <div className='horimetro-input-div-60'>
                                <Input
                                    type="number"
                                    name="horimetroSecond"

                                    handleOnChange={handleChange60}
                                    value={time.horimetroSecond || ""}
                                />
                            </div>
                            <div className='horimetro-input-text'> s </div>
                        </div>
                        
                            <Checkbox

                                text="Horimetro Zerado/Trocado"

                                name="reset"

                                handleOnChange={() => handleChangeCheckbox("reset", !newRecord.reset)}
                                check={newRecord.reset || false}
                            />

                        


                        <div className='bt-model-add bt-model-1 bt-width-m2' onClick={() => { submit() }} > Salvar registro</div>
                    </div>
                </div>

            </div >


        </div>
    )
}

export default FormRecord