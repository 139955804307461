import FormRegion from "./form/FormRegion"
import { useState, useEffect } from "react"
import { Link } from "react-router-dom"


import api from '../../utils/api'
/* Hooks */
import useFlashMessage from '../../hooks/useFlashMessage'
/* form */
import Input from "../../shared/components/form/input"


function ListRegion() {

    const [token] = useState(localStorage.getItem('token') || '')
    const { setFlashMessage } = useFlashMessage()
    const [regions, setRegions] = useState([])
    const [modalFormRegionRegister, setModalFormRegionRegister] = useState(false)
    const [modalFormRegionEdit, setModalFormRegionEdit] = useState(false)
    const [modalFormRegionDelete, setModalFormRegionDelete] = useState(false)
    const [dataFormRegion, setDataFormRegion] = useState({})
    const [ itemRegionDel, setItemRegionDel] = useState({})

    const closeModalFormRegionRegister = () => {
        setModalFormRegionRegister(false)
    }
    const closeModalFormRegionEdit = () => {
        setModalFormRegionEdit(false)
    }

    useEffect(() => {
        getAll()
    }, [token])

    async function getAll(){
        api.get('/region/getall', {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`
            }
        })
            .then((response) => {
                setRegions(response.data.regions)
            })
    }

    async function registerRegion(region) {
        let msgType = 'success'



        const data = await api.post('/region/new', region, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`
            }
        }).then((response) => {
            setModalFormRegionRegister(false)
            getAll()
            return response.data
        })
            .catch((err) => {
                msgType = 'error'
                return err.response.data
            })






        setFlashMessage(data.message, msgType)

        

    }

    async function updateRegion(region) {
        let msgType = 'success'
        
        
        const data = await api.patch(`region/${region._id}`, region, {
            headers: {
            Authorization: `Bearer ${JSON.parse(token)}`,
        },
        }).then((response) => {
            
            return response.data
        }).catch((err) => {
            
            msgType= 'error'
            return err.response.data
        })
        setFlashMessage(data.message, msgType)
        if (msgType !== 'error'){
            getAll()
            setModalFormRegionEdit(false)
           
            }

    }
    async function removeRegion(id) {
        let msgType = 'success'
        /*  Modal */
        setModalFormRegionDelete(false)

        const data = await api.delete(`/region/${id}`, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
            },
        }).then((response) => {
            const updatedArray = regions.filter((i) => i._id != id)
            setRegions(updatedArray)
            return response.data
        }).catch((err) => {

            msgType = 'error'
            return err.response.data
        })

        setFlashMessage(data.message, msgType)

    }


    /* Search */
    const [search, setSearch] = useState('')
    const searchLowerCase = search.toLowerCase()
    const regionSearch = regions.filter((search) => search.regionName.toLowerCase().includes(searchLowerCase))
    function handleSearch(e) {
        setSearch(e.target.value)


    }

    return (
        <div className='default-container-m1'>

            <h1 className='default-title-m1'> Regiões</h1>
            <div className="bt-model-3 bt-width-m2" onClick={() => { setModalFormRegionRegister(true) }}>Cadastrar</div>
            <form >
                <div className='bt-width-m1'>
                    <Input


                        type="text"
                        name="search"
                        placeholder="Buscar"
                        handleOnChange={handleSearch}

                    />
                </div>
            </form>
            <div className='List'>
                {/* Search */}
                {search.length > 0 ?
                    regionSearch.map((i) => (

                        <div className='ListRow' key={i._id}>
                            <div className='bold'>{i.regionName}</div>
                            <div className='action'>
                                <button className='bt edit' title='Editar' onClick={() => { setDataFormRegion(i); setModalFormRegionEdit(true) }}></button>
                                <button className='bt delete' title='Apagar' onClick={() => { setItemRegionDel(i); setModalFormRegionDelete(true) }}></button>

                            </div>

                        </div>

                    )) : regions.length > 0 ?
                        regions.map((i) => (

                            <div className='ListRow' key={i._id}>
                                <div className='bold'>{i.regionName}</div>
                                <div className='action'>
                                    <button className='bt edit' title='Editar' onClick={() => { setDataFormRegion(i); setModalFormRegionEdit(true) }}></button>
                                    <button className='bt delete' title='Apagar' onClick={() => { setItemRegionDel(i); setModalFormRegionDelete(true) }}></button>

                                </div>

                            </div>

                        )) : regions.length === 0 && <p> Não há regiões cadastrados</p>}


            </div>





            <div>
                {modalFormRegionRegister &&
                    <>
                        <FormRegion handleOnSubmit={registerRegion} closeModal={closeModalFormRegionRegister} title="Nova Região" btText="Adicionar" />
                    </>
                }

            </div>
            <div>
                {modalFormRegionEdit &&
                    <>
                        <FormRegion handleOnSubmit={updateRegion} closeModal={closeModalFormRegionEdit} data={dataFormRegion} title="Editar Região" btText="Salvar" />
                    </>
                }

            </div>
            <div>
                {modalFormRegionDelete &&
                    <>
                        <div>
                            <div className={"modal-background-1 "} >
                                <div className="modal-small default-model-1">
                                    <div className="box-x"> <div onClick={() => { setModalFormRegionDelete(false) }} className="x">X</div></div>

                                    <div >
                                        <div className='default-title-m3'> Deseja realmente apagar essa região ?</div>


                                        
                                       
                                    </div>
                                    <div >{itemRegionDel.regionName} </div>

                                    <div className="bt-box-model-flex-1">
                                            <div className='bt-model-delete bt-model-1 bt-width-m2' onClick={() => {removeRegion(itemRegionDel._id) }} >Apagar </div>
                                            <div className='bt-model-cancel bt-model-1 bt-width-m2' onClick={() => {setModalFormRegionDelete(false) }} >Cancelar </div>
                                        </div>

                                </div>

                            </div >
                        </div>
                    </>}
            </div>
        </div>
    )
}

export default ListRegion