import './footer.css'

function Footer() {

    return(
        <footer className="footer-box">
            <div></div>
            <div className='footer-box-version'>Copyright &#9400; 2024 - Deltabyte - v1.0.0</div>
        </footer>

    )
}

export default Footer