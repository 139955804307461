import { useState } from "react"
import "./ToggleSwitch.css"

function ToggleSwitch({ text,name, value, handleOnChange }) {
    const [checked, setChecked] = useState(value || false)

    return (
        <div className="toggle-switch">
            <div className="switch">
                <div className={value ? "checked slider round" : "slider round "}
                    onClick={handleOnChange}
                ></div>
            </div>
            <label htmlFor={name}>{text}</label>
        </div>
    )
}

export default ToggleSwitch

/* 
    <ToggleSwitch
        text={"Visualizar"}
        name={"serviceExp_View"}
        value={permission.serviceExp_View}
        handleOnChange={() => handleChangeTS("serviceExp_View", !permission.serviceExp_View)}
    />

*/