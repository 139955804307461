import api from '../utils/api'

import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useFlashMessage from './useFlashMessage'




export default function useAuth() {
    const [authenticated,  setAuthenticated] = useState(false)
    const [userName, setUserName] = useState()
    const [access,  setAccess] = useState({})
    const [permissionClient, setPermissionClient] = useState({})
    const [permissionProvider, setPermissionProvider] = useState({})
    const { setFlashMessage } = useFlashMessage()
    const navigate = useNavigate()
    

    useEffect(() => {
        const token = localStorage.getItem('token')
        const userStorageName = localStorage.getItem('userName')

        if (token) {
            api.defaults.headers.Authorization = `Bearer ${JSON.parse(token)}`
            setAuthenticated(true)
            setUserName(userStorageName)
            
            
        }
        
        
    }, [])

    /* FUNÇÃO DE REGISTRO DE NOVOS USUARIOS */
    async function register(user) {

        let msgText = 'Cadastro realizado com sucesso !'
        let msgType = 'success'
        try {
            const data = await api.post('/user/register', user).then((response) => {
                return response.data
            })
          
          navigate('/system-user/list')
            
        } catch(error){

           msgText = error.response.data.message
           msgType = 'error'
            
        }

        setFlashMessage(msgText, msgType)

    }

    /* FUNÇÃO DE LOGIN */
    async function login(user) {
        let msgText = 'Login realizado com sucesso !'
        let msgType = 'success'

        try {
            
            const data = await api.post('/user/login', user).then((response) => {
                
                return response.data
            })
           

            await authUser(data)
            
        } catch (error) {
          
            msgText = error.response.data.message
            msgType = 'error'
            
        }
        setFlashMessage(msgText, msgType)

    }

    /* FUNÇÃO DE AUTENTICAR */
    async function authUser(data){
        
        

       setAuthenticated(true)
      
      /*  let permission = data.permission[0] */
       
       /* setPermissionClient(data.permission[0]) */
       setUserName(data.userName)
       let user_name = data.userName.split(" ")
       user_name = user_name[0]
       
       
       
       

       setAccess(data.permission)
       
        localStorage.setItem('userName', user_name)
        localStorage.setItem('token', JSON.stringify(data.token))
        
        
        navigate('/')
        window.location.reload()

        

    }
   

    async function logout(){
        const msgText = 'Logout realizado com sucesso !'
        const msgType = 'success'

        setAuthenticated(false)

        localStorage.removeItem('token')
        localStorage.removeItem('userName')
        api.defaults.headers.Authorization = undefined

        navigate('/login')

        setFlashMessage(msgText, msgType)
    }   

    return { authenticated, access, permissionClient, userName,  register, login, logout}
}