import './ConfirmModal.css'

export default function ConfirmModal({openModal, closeModal,confirm, id, name, messageModal, btConfirmValue, btConfirmStyle, btCancelStyle}) {
  
    function confirmation(){
        confirm(id)
        
    }

     if(openModal){
        return (

            <section >
                <div className="backgroundModal">
               
                <div className='divModal'>
                    <div className='divMessage'>{messageModal}<p><b>{name}</b></p> </div>
                    <div className='divButton'>
                        <button className={btConfirmStyle ||'btDelete'} onClick={confirmation}>{btConfirmValue || 'OK'}</button>
                        <button className={btCancelStyle ||'btCancel'} onClick={closeModal}>Cancelar</button>
                    </div>
                    
                </div>
                </div>
            </section>
            )
        }

     

     return null

}

