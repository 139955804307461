import api from '../../utils/api'

import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Navigate, useNavigate } from 'react-router-dom'




/* Hooks */
import useFlashMessage from '../../hooks/useFlashMessage'
import useMyPermission from '../../hooks/useMyPermission'
//form
import FormClient from './components/FormClient'

function EditClient(){
    const [client, setClient] = useState({})
    const [myPermission, setMyPermission] = useState({ group: "", })
    const [token] = useState(localStorage.getItem('token') || '')
    const {id} = useParams()
    const {setFlashMessage} = useFlashMessage()
    const navigate = useNavigate()

    //CHECK PERMISSIONS
    const { myPermissionTest } = useMyPermission()
    const myPermissionEdit = myPermissionTest(myPermission, "client_Edit")
    
    
    useEffect(() => {

        api.get(`/client/${id}`, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
            },
            
        }).then((response) => {
            setMyPermission(response.data.permission)
            setClient(response.data.client)
        })
    }, [token, id])

    async function updateClient(client) {
        let msgType = 'success'
        
        
        const data = await api.patch(`client/${client._id}`, client, {
            headers: {
            Authorization: `Bearer ${JSON.parse(token)}`,
        },
        }).then((response) => {
            
            return response.data
        }).catch((err) => {
            
            msgType= 'error'
            return err.response.data
        })
        setFlashMessage(data.message, msgType)
        if (msgType !== 'error'){
            
            navigate('/client')
            }

    }


    return (
        myPermissionEdit ? (
        <section className='default-container-m1'>
            {client.clientName && (
                <FormClient handleSubmit={updateClient} btnText = "Atualizar" btnCancelLink = "/client" clientData={client}/>
            )}
        </section>
        ):(
            <div></div>
        )
    )
}

export default EditClient