import api from '../../utils/api'

import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Input from '../../shared/components/form/input'
/* Function */
import { monthYear, dayMonthYear, secondToHour, secondToMinute, secondToSecond } from "../../shared/functions/Date"

//icon
import { FaCreativeCommonsZero } from "react-icons/fa6";

import "./EditHorimetro.css"
/* Hooks */
import useFlashMessage from '../../hooks/useFlashMessage'
import useMyPermission from '../../hooks/useMyPermission'

import FormRecordHorimetro from './Components/form/FormRecordHorimetro'
import FormRecord from './Components/form/FormRecord'
import ModalDelete from './Components/form/ModalDelete'
function EditHorimetro() {
    const [client, setClient] = useState({})
    const [tab, setTab] = useState("horimetro")
    const [radiator, setRadiator] = useState([])
    const [changeBattery, setChangeBattery] = useState([])
    const [injectionPump, setInjectionPump] = useState([])
    const [tankCleaning, setTankCleaning] = useState([])
    const [horimetro, setHorimetro] = useState([])
    const [modalEditRecordRadiator, setModalEditRecordRadiator] = useState(false)
    const [modalEditRecordInjectionPump, setModalEditRecordInjectionPump] = useState(false)
    const [modalEditRecordChangeBattery, setModalEditRecordChangeBattery] = useState(false)
    const [modalEditRecordTankCleaning, setModalEditRecordTankCleaning] = useState(false)
    const [modalEditHorimetro, setModalEditHorimetro] = useState(false)
    const [inputName, setInputName] = useState("")
    const [record, setRecord] = useState({})
    const [recordHorimetro, setRecordHorimetro] = useState({})
    const [token] = useState(localStorage.getItem('token') || '')
    const { id } = useParams()
    const { setFlashMessage } = useFlashMessage()
    const [modalDelete, setModalDelete] = useState(false)
    const [modalDeleteInjectionPump, setModalDeleteInjectionPump] = useState(false)
    const [modalDeleteChangeBattery, setModalDeleteChangeBattery] = useState(false)
    const [modalDeleteTankCleaning, setModalDeleteTankCleaning] = useState(false)
    const [modalDeleteHorimetro, setModalDeleteHorimetro] = useState(false)
    const [pathDelete, setPathDelete] = useState("")

    const [myPermission, setMyPermission] = useState({ group: "", })
    //CHECK PERMISSIONS
    const { myPermissionTest } = useMyPermission()
    const myPermissionEdit = myPermissionTest(myPermission, "serviceExp_Edit")
    const myPermissionDelete = myPermissionTest(myPermission, "serviceExp_Delete")
    const myPermissionView = myPermissionTest(myPermission, "serviceExp_View")
    const myPermissionCreate = myPermissionTest(myPermission, "serviceExp_Create")


    useEffect(() => {

        api.get(`/clientServiceExpiration/getAll/${id}`, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
            },

        }).then((response) => {

            setMyPermission(response.data.permission)
            setClient(response.data.client)
            setRadiator(response.data.radiator)
            setChangeBattery(response.data.changeBattery)
            setInjectionPump(response.data.injectionPump)
            setTankCleaning(response.data.tankCleaning)
            setHorimetro(response.data.horimetro)

        })
    }, [token, id])


    async function updateRadiator(info) {
        let msgType = "success"
        const data = await api.patch(`radiator/${info._id}`, info, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
            },
        }).then((response) => {
            return response.data
        }).catch((err) => {
            msgType = 'error'
            return err.response.data
        })
        setFlashMessage(data.message, msgType)
        if (msgType === "success") {
            setModalEditRecordRadiator(false)
            api.get(`/radiator/${id}`, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(token)}`,
                },

            }).then((response) => {
                setRadiator(response.data.radiator)
            })

        }

    }
    async function updateChangeBattery(info) {
        let msgType = "success"
        const data = await api.patch(`changeBattery/${info._id}`, info, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
            },
        }).then((response) => {
            return response.data
        }).catch((err) => {
            msgType = 'error'
            return err.response.data
        })
        setFlashMessage(data.message, msgType)
        if (msgType === "success") {
            setModalEditRecordChangeBattery(false)
            api.get(`/changeBattery/${id}`, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(token)}`,
                },

            }).then((response) => {
                setChangeBattery(response.data.changeBattery)
            })

        }


    }
    async function updateInjectionPump(info) {
        let msgType = "success"
        const data = await api.patch(`injectionPump/${info._id}`, info, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
            },
        }).then((response) => {
            return response.data
        }).catch((err) => {
            msgType = 'error'
            return err.response.data
        })
        setFlashMessage(data.message, msgType)
        if (msgType === "success") {
            setModalEditRecordInjectionPump(false)
            api.get(`/injectionPump/${id}`, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(token)}`,
                },

            }).then((response) => {
                setInjectionPump(response.data.injectionPump)
            })
        }
    }
    async function updateTankCleaning(info) {
        let msgType = "success"
        const data = await api.patch(`tankCleaning/${info._id}`, info, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
            },
        }).then((response) => {
            return response.data
        }).catch((err) => {
            msgType = 'error'
            return err.response.data
        })
        setFlashMessage(data.message, msgType)
        if (msgType === "success") {
            setModalEditRecordTankCleaning(false)
            api.get(`/tankCleaning/${id}`, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(token)}`,
                },

            }).then((response) => {

                setTankCleaning(response.data.tankCleaning)
            })
        }
    }
    async function updateHorimetro(info) {
        let msgType = "success"
        const data = await api.patch(`horimetro/${info._id}`, info, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
            },
        }).then((response) => {
            return response.data
        }).catch((err) => {
            msgType = 'error'
            return err.response.data
        })
        setFlashMessage(data.message, msgType)
        if (msgType === "success") {
            setModalEditHorimetro(false)
            api.get(`/horimetro/${id}`, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(token)}`,
                },

            }).then((response) => {

                setHorimetro(response.data.horimetro)
            })
        }
    }

    function handleOnDelete() {
        setModalDelete(true)
    }

    async function deleteRecord(info) {
        let msgType = "success"
        const data = await api.delete(`/${pathDelete}`, info, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
            },
        }).then((response) => {
            return response.data
        }).catch((err) => {
            msgType = 'error'
            return err.response.data
        })
        setFlashMessage(data.message, msgType)
        if (msgType === "success") {
            setModalDelete(false)
            api.get(`/clientServiceExpiration/getAll/${id}`, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(token)}`,
                },

            }).then((response) => {
               
                setClient(response.data.client)
                setRadiator(response.data.radiator)
                setChangeBattery(response.data.changeBattery)
                setInjectionPump(response.data.injectionPump)
                setTankCleaning(response.data.tankCleaning)
                setHorimetro(response.data.horimetro)

            })
        }
    }
    function handleChangeCheckbox(id) {

        let checkboxItem = client
        if ("overdueMaintenance" === id) {
            setClient({ ...checkboxItem, overdueMaintenance: !checkboxItem.overdueMaintenance })
        } if ("validMaintenance" === id) {
            setClient({ ...checkboxItem, validMaintenance: !checkboxItem.validMaintenance })
        } if ("materialBest" === id) {
            setClient({ ...checkboxItem, materialBest: !checkboxItem.materialBest })
        } if ("emergency" === id) {
            setClient({ ...checkboxItem, emergency: !checkboxItem.emergency })
        } if ("rushHour" === id) {
            setClient({ ...checkboxItem, rushHour: !checkboxItem.rushHour })
        }



    }

    async function updateClient(client) {
        let msgType = 'success'


        const data = await api.patch(`client/${client._id}`, client, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
            },
        }).then((response) => {

            return response.data
        }).catch((err) => {

            msgType = 'error'
            return err.response.data
        })
        setFlashMessage(data.message, msgType)


    }

    return (
        myPermissionView ? (
        <div className='default-container-m1'>
            <div>
                <h1>{client.clientName}</h1>
            </div>
            <div className="">
                <div className="input-center">
                    <label> Modo de operações</label>
                    <div className="box-operation-mode">
                       {/*  <div>
                            <input type="checkbox" id="overdueMaintenance" name="overdueMaintenance" checked={client.overdueMaintenance} onChange={() => { handleChangeCheckbox("overdueMaintenance") }} />
                            <label htmlFor="overdueMaintenance">Manutenção vencida</label>
                        </div>
                        <div >
                            <input type="checkbox" id="manutencaoValida" name="validMaintenance" checked={client.validMaintenance} onChange={() => { handleChangeCheckbox("validMaintenance") }} />
                            <label htmlFor="manutencaoValida">Manutenção valida</label>
                        </div> */}
                        <div className="box-radio-text">
                            <input type="checkbox" id="materialBest" name="materialBest" checked={client.materialBest} onChange={() => { handleChangeCheckbox("materialBest") }} />
                            <label htmlFor="materialBest">Cliente com material de revisão fornecido pela Bestforce</label>
                        </div>
                        <div>
                            <input type="checkbox" id="emergencia" name="emergency" checked={client.emergency} onChange={() => { handleChangeCheckbox("emergency") }} />
                            <label htmlFor="emergencia">Emergência</label>
                        </div>
                        <div>
                            <input type="checkbox" id="horarioPonta" name="rushHour" checked={client.rushHour} onChange={() => { handleChangeCheckbox("rushHour") }} />
                            <label htmlFor="horarioPonta">Horário de ponta</label>
                        </div>
                    </div>
                    {myPermissionEdit &&
                    <div className='bt-model-3 bt-width-m2' onClick={() => { updateClient(client) }} > Salvar</div>
                    }

                </div>
            </div>
            <p></p>
            <div>
                <div className='div-tab'>
                    <div className={tab === "radiator" ? "bt-tab-m1 bt-width-m2 tab-selected" : "bt-tab-m1 bt-width-m2"} onClick={() => { setTab("radiator") }}>radiador</div>
                    <div className={tab === "battery" ? "bt-tab-m1 bt-width-m2 tab-selected" : "bt-tab-m1 bt-width-m2"} onClick={() => { setTab("battery") }}>Bateria</div>
                    <div className={tab === "pump" ? "bt-tab-m1 bt-width-m2 tab-selected" : "bt-tab-m1 bt-width-m2"} onClick={() => { setTab("pump") }}>Bomba injetora</div>
                    <div className={tab === "tank" ? "bt-tab-m1 bt-width-m2 tab-selected" : "bt-tab-m1 bt-width-m2"} onClick={() => { setTab("tank") }}>Limpeza de tanque</div>
                    <div className={tab === "horimetro" ? "bt-tab-m1 bt-width-m2 tab-selected" : "bt-tab-m1 bt-width-m2"} onClick={() => { setTab("horimetro") }}>Horímetro</div>
                </div>

                <div className='div-record'>
                    <div className={tab === "radiator" ? "modal-display-flex div-record-item" : "modal-display-none"}>
                        {radiator.length > 0 ?
                            radiator.map((i) => (

                                <div key={i._id} className='edit-horimetro-box-list' >
                                    <div className='edit-horimetro-box-list-col-1' >{monthYear(i.date)} </div>
                                    <div className='edit-horimetro-box-list-col-3'>
                                        {myPermissionEdit &&
                                            <div className='bt edit' title='Editar' onClick={() => { setRecord(i); setModalEditRecordRadiator(true) }} ></div>
                                        }
                                        {myPermissionDelete &&
                                            <button className='bt delete' title='Apagar' onClick={() => { setPathDelete(`radiator/${i._id}`); setModalDelete(true); }}></button>
                                        }
                                    </div>

                                </div>
                            )
                            ) : radiator.length === 0 && <p> Não há registros cadastrados</p>}
                    </div>

                    <div className={tab === "battery" ? "modal-display-flex div-record-item" : "modal-display-none"}>
                        {changeBattery.length > 0 ?
                            changeBattery.map((i) => (

                                <div key={i._id} className='edit-horimetro-box-list' >
                                    <div className='edit-horimetro-box-list-col-1' >{monthYear(i.date)} </div>
                                    <div className='edit-horimetro-box-list-col-3'>
                                        {myPermissionEdit &&
                                            <div className='bt edit' title='Editar' onClick={() => { setRecord(i); setModalEditRecordChangeBattery(true) }} ></div>
                                        }
                                        {myPermissionDelete &&
                                            <button className='bt delete' title='Apagar' onClick={() => { setPathDelete(`changeBattery/${i._id}`); setModalDelete(true); }}></button>
                                        }
                                    </div>

                                </div>
                            )
                            ) : changeBattery.length === 0 && <p> Não há registros cadastrados</p>}
                    </div>

                    <div className={tab === "pump" ? "modal-display-flex div-record-item" : "modal-display-none"}>
                        {injectionPump.length > 0 ?
                            injectionPump.map((i) => (

                                <div key={i._id} className='edit-horimetro-box-list'  >
                                    <div className='edit-horimetro-box-list-col-1' >{monthYear(i.date)} </div>
                                    <div className='edit-horimetro-box-list-col-3'>
                                        {myPermissionEdit &&
                                            <div className='bt edit' title='Editar' onClick={() => { setRecord(i); setModalEditRecordInjectionPump(true) }} ></div>
                                        }
                                        {myPermissionDelete &&
                                            <button className='bt delete' title='Apagar' onClick={() => { setPathDelete(`injectionPump/${i._id}`); setModalDelete(true); }}></button>
                                        }
                                    </div>

                                </div>
                            )
                            ) : injectionPump.length === 0 && <p> Não há registros cadastrados</p>}
                    </div>
                    <div className={tab === "tank" ? "modal-display-flex div-record-item" : "modal-display-none"}>
                        {tankCleaning.length > 0 ?
                            tankCleaning.map((i) => (
                                <div key={i._id} className='edit-horimetro-box-list'  >
                                    <div className='edit-horimetro-box-list-col-1' >{monthYear(i.date)} </div>
                                    <div className='edit-horimetro-box-list-col-3'>
                                        {myPermissionEdit &&
                                            <div className='bt edit' title='Editar' onClick={() => { setRecord(i); setModalEditRecordTankCleaning(true) }} ></div>
                                        }
                                        {myPermissionDelete &&
                                            <button className='bt delete' title='Apagar' onClick={() => { setPathDelete(`tankCleaning/${i._id}`); setModalDelete(true); }}></button>
                                        }
                                    </div>

                                </div>
                            )
                            ) : tankCleaning.length === 0 && <p> Não há registros cadastrados</p>}
                    </div>
                    <div className={tab === "horimetro" ? "modal-display-flex div-record-item" : "modal-display-none"}>
                        {horimetro.length > 0 ?
                            horimetro.map((i) => (
                                <div key={i._id} className={i.changeOilAndFilter ? 'edit-horimetro-box-list change-oil-and-filter-style' : 'edit-horimetro-box-list'}>
                                    {i.reset ? <div> <FaCreativeCommonsZero/> </div> : <div> </div>}
                                    <div className='edit-horimetro-box-list-col-1' >{dayMonthYear(i.horimetroDate)} </div>
                                    <div className='edit-horimetro-box-list-col-2'> {`${secondToHour(i.horimetroRecordSeconds)}h ${secondToMinute(i.horimetroRecordSeconds)}min ${secondToSecond(i.horimetroRecordSeconds)}s `} </div>
                                    <div className='edit-horimetro-box-list-col-3'>
                                        {myPermissionEdit &&
                                            <div className='bt edit' title='Editar' onClick={() => { setRecordHorimetro(i); setModalEditHorimetro(true) }} ></div>
                                        }
                                        {myPermissionDelete &&
                                            <button className='bt delete' title='Apagar' onClick={() => { setPathDelete(`horimetro/${i._id}`); setModalDelete(true); }}></button>
                                        }
                                    </div>

                                </div>
                            )
                            ) : horimetro.length === 0 && <p> Não há registros cadastrados</p>}
                    </div>


                </div>
            </div>

            {modalEditHorimetro &&
                <>
                    <FormRecordHorimetro data={recordHorimetro} closeModal={() => { setModalEditHorimetro(false) }} handleOnSubmit={updateHorimetro} />
                </>
            }
            {modalEditRecordTankCleaning &&
                <>
                    <FormRecord data={record} closeModal={() => { setModalEditRecordTankCleaning(false) }} handleOnSubmit={updateTankCleaning} />
                </>
            }
            {modalEditRecordRadiator &&
                <>
                    <FormRecord data={record} closeModal={() => { setModalEditRecordRadiator(false) }} handleOnSubmit={updateRadiator} />
                </>
            }
            {modalEditRecordChangeBattery &&
                <>
                    <FormRecord data={record} closeModal={() => { setModalEditRecordChangeBattery(false) }} handleOnSubmit={updateChangeBattery} />
                </>
            }
            {modalEditRecordInjectionPump &&
                <>
                    <FormRecord data={record} closeModal={() => { setModalEditRecordInjectionPump(false) }} handleOnSubmit={updateInjectionPump} />
                </>
            }


            {modalDelete &&
                <>
                    <div>
                        <div className={"modal-background-1 "} >
                            <div className="modal-small default-model-1">
                                <div className="box-x"> <div onClick={() => { setModalDelete(false) }} className="x">X</div></div>

                                <div >
                                    <div className='default-title-m2'>Deseja realmente apagar esse registro ?</div>




                                    <div className='bt-model-add bt-model-1 bt-width-m2' onClick={() => { deleteRecord() }} > Apagar</div>
                                </div>

                            </div>

                        </div >
                    </div>
                </>
            }
        </div>
        ):(
            <div></div>
        )
    )
}
export default EditHorimetro