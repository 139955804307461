import { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import Input from "../../../shared/components/form/input"
/* CSS */
import './login.css'

/* img */
import logo from '../../../shared/img/logo.png'


/* contexts */
import { Context } from '../../../context/UserContext'


function Login() {
  const [user, setUser] = useState({})
  const { login } = useContext(Context)

  function handleChange(e) {
    setUser({ ...user, [e.target.name]: e.target.value })
  }

  function handleSubmit(e) {
    e.preventDefault()

    login(user)

  }

  return (
    <section className="login-container">
      <div className="login-center">
        <img className='login-logo' src={logo} />

        <form onSubmit={handleSubmit} className='input-login-form'>
          <div className="form-client-col-3 form-group">
            <Input
              text="Usuário"
              type="text"
              name="userName"
              placeholder="Digite o seu nome"
              handleOnChange={handleChange}
            />
          </div>
          <div className="form-client-col-3 form-group">
            <Input
              text="Senha"
              type="password"
              name="password"
              placeholder="Digite a sua senha"
              handleOnChange={handleChange}
            />
          </div>
          <div className="form-client-col-3 form-group">

            <input className="bt-model-add bt-model-1 " type="submit" value="Entrar" />

          </div>

        </form>
      </div >
    </section >
  )
}

export default Login