import './formRegion.css'

import { useState } from "react"
//FORM
import Input from '../../../shared/components/form/input'

//FUNCTION
import DataToSelect from '../../../shared/functions/DataToSelect'

function FormRegion({closeModal, data , handleOnSubmit ,  title , btText}){

    const [region, setRegion] = useState(data || {})
    function handleChange(e){
        
        setRegion({ ...region, [e.target.name]: e.target.value , label: e.target.value, value: e.target.value  })

    }
    

    return (
        <div>
            <div className={"modal-background-1 "} >
                <div className="modal-small default-model-1">
                    <div className="box-x"> <div onClick={() => { closeModal() }} className="x">X</div></div>
                   
                    <div >
                    <div className='default-title-m2'>{title}</div>
                        <Input
                            type="text"
                            name="regionName"
                            text="Região"
                            handleOnChange={handleChange}
                            value={region.regionName || ""}
                        />


                        
                        <div className='bt-model-add bt-model-1 bt-width-m2' onClick={() => { handleOnSubmit(region) }} > {btText}</div>
                    </div>

                </div>

            </div >
        </div>
    )
}

export default FormRegion