import { useState, useContext } from 'react'
import Input from "../../../shared/components/form/input"
import { Link } from 'react-router-dom'

import './login.css'

//icon
import { PiCheckCircleFill } from "react-icons/pi";
import { MdRadioButtonUnchecked } from "react-icons/md";


/* contexts */
import { Context } from '../../../context/UserContext'


function Register() {
  const [user, setUser] = useState({})
  const [permission, setPermission] = useState({})
  const { register } = useContext(Context)
  const [newPwd, setNewPwd] = useState({})
  //password validation states
  const [lowerValidated, setLowerValidated] = useState(false)
  const [upperValidated, setUpperValidated] = useState(false)
  const [numberValidated, setNumberValidated] = useState(false)
  const [specialValidated, setSpecialValidated] = useState(false)
  const [lengthValidated, setLengthValidated] = useState(false)

  function handleChange(e) {
    setUser({ ...user, [e.target.name]: e.target.value })
  }
  function handleChangePermission(e) {
    setPermission({ ...permission, [e.target.name]: e.target.value })
  }

  function handleSubmit(e) {
    e.preventDefault()
    let data = { ...user, permission: permission }
    register(data)
  }

  const handleChangePwd = (e) => {
    let value = e.target.value
    setUser({ ...user, [e.target.name]: e.target.value })
    validatePwd(value)

  }

  function validatePwd(pwd) {
    //regex
    const lower = new RegExp('(?=.*[a-z])')
    const upper = new RegExp('(?=.*[A-Z])')
    const number = new RegExp('(?=.*[0-9])')
    const special = new RegExp('(?=.*[!@#\$%\^&\*])')
    const length = new RegExp('(?=.{8,})')

    //lowercase validation
    if (lower.test(pwd)) {
      setLowerValidated(true)
    } else {
      setLowerValidated(false)
    }
    //uppercase validation
    if (upper.test(pwd)) {
      setUpperValidated(true)
    } else {
      setUpperValidated(false)
    }
    //uppercase validation
    if (number.test(pwd)) {
      setNumberValidated(true)
    } else {
      setNumberValidated(false)
    }
    //special validation
    if (special.test(pwd)) {
      setSpecialValidated(true)
    } else {
      setSpecialValidated(false)
    }
    //lenght validation
    if (length.test(pwd)) {
      setLengthValidated(true)
    } else {
      setLengthValidated(false)
    }
  }
  return (
    <section className="shered-container-m1">
      <div className='shared-container-form'>
        <div className="shared-center-form">

          <h1 className='shared-title-m1'>Registrar</h1>
          <div className="form-client-col-1 form-group">
            <form onSubmit={handleSubmit} className='input-login-form'>
              
              <div className="form-client-col-1 form-group">
              <Input

                text="Nome completo"
                type="text"
                name="name"
                placeholder="digite o nome do usuário"
                handleOnChange={handleChange}
                value={user.name || ""}
              />

            </div>
            <div className="form-client-col-1 form-group">
              <Input

                text="E-mail"
                type="email"
                name="email"
                placeholder="digite o nome do usuário"
                handleOnChange={handleChange}
                value={user.email || ""}
              />

            </div>
            <div className="form-client-col-1 form-group">
              <Input

                text="Data de Nascimento"
                type="date"
                name="birth"
                placeholder="digite a data de nascimento"
                handleOnChange={handleChange}
                value={user.birth || ""}
              />

            </div>
            <div className="form-client-col-1 form-group">
              <Input

                text="Telefone whattsapp"
                type="tel"
                name="phone"
                placeholder="digite a data de nascimento"
                handleOnChange={handleChange}
                value={user.phone || ""}
              />

            </div>
            <div className="form-client-col-1 form-group">
              <Input

                text="Grupo"
                type="text"
                name="group"
                placeholder="digite o grupo"
                handleOnChange={handleChangePermission}
                value={permission.group || ""}
              />

            </div>

            <div className="form-client-col-1 form-group">
              <Input

                text="Usuário"
                type="text"
                name="userName"
                placeholder="digite o usuário de acesso"
                handleOnChange={handleChange}
                value={user.userName || ""}
              />

            </div>

            <div className="form-client-col-1 form-group">
                    <Input

                      text="Senha"
                      type="password"
                      name="password"
                      placeholder="digite a senha"
                      handleOnChange={handleChangePwd}
                      value={user.password || ""}
                    />

                  </div >
                  <div className="validation-box form-client-col-1 form-group">
                    <div className={upperValidated ? "validation-item-true" : "validation-item-false"}><div className="validation-item-icon">{upperValidated ? <PiCheckCircleFill /> : <MdRadioButtonUnchecked />}</div>Pelo menos uma letra maiúscula</div>
                    <div className={lowerValidated ? "validation-item-true" : "validation-item-false"}><div className="validation-item-icon">{lowerValidated ? <PiCheckCircleFill /> : <MdRadioButtonUnchecked />}</div>Pelo menos uma letra minúscula</div>
                    <div className={numberValidated ? "validation-item-true" : "validation-item-false"}><div className="validation-item-icon">{numberValidated ? <PiCheckCircleFill /> : <MdRadioButtonUnchecked />}</div>Pelo menos um número</div>
                    <div className={specialValidated ? "validation-item-true" : "validation-item-false"}><div className="validation-item-icon">{specialValidated ? <PiCheckCircleFill /> : <MdRadioButtonUnchecked />}</div>Pelo menos um caractere especial</div>
                    <div className={lengthValidated ? "validation-item-true" : "validation-item-false"}><div className="validation-item-icon">{lengthValidated ? <PiCheckCircleFill /> : <MdRadioButtonUnchecked />}</div>Pelo menos 8 caracteres</div>

                  </div>
                  <div className="form-client-col-1 form-group">
                    <Input

                      text="Confirmar senha"
                      type="password"
                      name="confirmPassword"
                      placeholder="repita a senha"
                      handleOnChange={handleChange}
                      value={user.confirmPassword || ""}
                    />


                  </div>

              <input className="bt-model-add bt-model-1 bt-width-m2" type="submit" value="Cadastrar" />
            </form>
            
          </div>
        </div>
      </div>
    </section>
  )
}

export default Register