import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './App.css';
import './shared/css/shared.css'

//components
import Navbar from "./shared/navbar/navbar";
import Message from "./shared/components/FlashMessage/Message"
import Container from "./shared/container/container"
import Footer from "./shared/footer/footer"


/* private pages */
import PrivateRoute from './shared/privateRoute/PrivateRoute';


//pages
import Login from "./pages/auth/pages/Login"
import Register from "./pages/auth/pages/Register"
import Home from './pages/home/Home'

//client
import Client from "./pages/client/Client"
import NewClient from "./pages/client/NewClient"
import EditClient from "./pages/client/EditClient"

//system user
import NewSystemUser from './pages/system_user/NewSystemUser';
import ListSystemUser from './pages/system_user/ListSystemUser';
import EditSystemUser from './pages/system_user/EditSystemUser';
import ListHistoric from './pages/Historic/ListHistoric';

//region
import ListRegion from './pages/region/ListRegion';


//Horimetro
import ListHorimetro from './pages/Horimetro/ListHorimetro'
import EditHorimetro from './pages/Horimetro/EditHorimetro'


/* context */
import { UserProvider } from './context/UserContext';

function App() {
  return (
    <Router>
      <UserProvider>
        <Navbar />
        <Message />
        <Container>
          <Routes>
            <Route path="/login" exact element={<Login />} />
            <Route path="/register" exact element={<Register/>} />
           

            
            <Route element={<PrivateRoute />}>
            <Route path='/' exact element={<Home/>} />
            <Route path='/client' exact element={<Client />} />
            <Route path='/client/new' exact element={<NewClient />} />
            <Route path='/client/edit/:id' exact element={<EditClient />} />

            <Route path='/region/list' exact element={<ListRegion />} />

            <Route path='/horimetro/list' exact element={<ListHorimetro />} />
            <Route path='/horimetro/edit/:id' exact element={<EditHorimetro />} />

            <Route path='/system-user/new' exact element={<NewSystemUser />} />
            <Route path='/system-user/list' exact element={<ListSystemUser />} />
            <Route path='/system-user/edit/:id' exact element={<EditSystemUser />} />
            <Route path='/historic/list' exact element={<ListHistoric />} />
            </Route>
          </Routes>

</Container>
<Footer/>
        
      </UserProvider>
    </Router>
  );
}

export default App;
