import './ListHorimetro.css'
import api from '../../utils/api'
import SideBar from './Components/SideBar/SideBar'
import ListServiceExpiration from './Components/table/ListServiceExpiration'
//Hooks
import useMyPermission from '../../hooks/useMyPermission'



import { useEffect, useState } from 'react';

function ListHorimetro(){

    const [filter, setFilter] = useState("")
    const [listClient, setListClient] = useState([])
    const [token] = useState(localStorage.getItem('token') || '')
    const [myPermission, setMyPermission] = useState({ group: "", })
    //CHECK PERMISSIONS
    const { myPermissionTest } = useMyPermission()
    const myPermissionView = myPermissionTest(myPermission, "serviceExp_View")
   

    useEffect(() => {

        api.get(`/user/get/permission`, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
            },

        }).then((response) => {

            setMyPermission(response.data.permission)


        })

    }, [token]

    )
    
    const zoneFilter = (zone) => {
        
        setFilter(zone)
    }
    


    return (
myPermissionView ? (
        <>
        <section className="box-container" >
            
            <SideBar  filter={zoneFilter} selectFilter={filter}/>
            <ListServiceExpiration   filter={filter}/>
            
        </section>
       
        </>
):(
    <div></div>
)
    )
}

export default ListHorimetro;