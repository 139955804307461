import { useState } from 'react'
import Input from '../../../../shared/components/form/input'


function FormRecord({title,data, closeModal, handleOnSubmit}) {
    const [newRecord, setNewRecord] = useState(data || "")
    
    function handleChange(e) {
        setNewRecord({...newRecord, date: e.target.value})
    }
    async function submit(){
       
       handleOnSubmit(newRecord)
    }


    return (

        <div>

           
                <div>
                    <div className={"modal-background-1 "} >
                        <div className="modal-small default-model-1">
                            <div className="box-x"> <div onClick={() => { closeModal() }} className="x">X</div></div>

                            <div >
                                <div className='default-title-m2'>{title}</div>
                                <Input
                                    type="month"
                                    
                                    text="Mês e Ano"
                                    handleOnChange={handleChange}
                                    value={newRecord.date || ""}
                                    
                                />



                                <div className='bt-model-add bt-model-1 bt-width-m2' onClick={() => { submit() }} > Salvar registro</div>
                            </div>

                        </div>

                    </div >
                </div>
           
        </div>
    )
}

export default FormRecord