import api from '../../../../utils/api'
import { useState, useEffect } from "react"
import Input from "../../../../shared/components/form/input"

import './listServiceExpiration.css'

import { monthToNominal, monthToNominalShort, monthYear, expiratioDateCalc, secondToHMinS } from "../../../../shared/functions/Date"
//import { ExpiratioTimeCalc} from "../../../../shared/functions/ExpirationTimeCalc"
//Modal
import ModalFormServiceExpiration from "../form/ModalFormServiceExpiration"

//Hooks
import useMyPermission from '../../../../hooks/useMyPermission'

//image
import iconMaterial from '../../img/iconMaterialBest.png'
import iconEmergencia from '../../img/iconEmergencia.png'
import iconHorario from '../../img/iconHorario.png'
import iconManuValida from '../../img/iconManuValida.png'
import iconManuVencida from '../../img/iconManuVencida.png'
function getDate() {
    const today = new Date()
    const month = today.getMonth() + 1
    const year = today.getFullYear()

    return `${year}-${month}`;
}

function ListServiceExpiration({ filter }) {

    const [token] = useState(localStorage.getItem('token') || '')
    const [listClient, setListClient] = useState([])
    const [inputFilter, setInputFilter] = useState("")
    const [modalClientServicesExpiration, setModalClientServicesExpiration] = useState(false)
    const [dataModalClientServicesExpiration, setDataModalClientServicesExpiration] = useState({})
    const [currentDate, setCurrentDate] = useState(new Date().getTime())

    const [myPermission, setMyPermission] = useState({ group: "", })



    useEffect(() => {
        getAllClientData()
    }, [token])

    async function getAllClientData() {
        api.get('/clientServiceExpiration/getallclientservices', {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`
            }
        })
            .then((response) => {
                setMyPermission(response.data.permission)
                setListClient(response.data.clients)
            })
    }
    const updateDataClient = () => {
        getAllClientData()
    }
    let tableFilter
    if (filter !== "") {

        tableFilter = listClient.filter((item) => item.region === filter)
        const searchLowerCase = inputFilter.toLowerCase()
        tableFilter = tableFilter.filter((item) => item.clientName.toLowerCase().includes(searchLowerCase));

        if (tableFilter.length == 0) {
            tableFilter = ""

        }

    } else {
        const searchLowerCase = inputFilter.toLowerCase()
        tableFilter = listClient.filter((item) => item.clientName.toLowerCase().includes(searchLowerCase));
        if (tableFilter.length == 0) {
            tableFilter = ""

        }
    }


    function myFilter(e) {
        setInputFilter(e.target.value)


    }


    function OpenModalClientServicesExpiration(data) {
        setDataModalClientServicesExpiration(data)
        setModalClientServicesExpiration(true)
    }
    function anyExpire(item) {
        let expHorNumber = 900000
        if ((new Date(item.nextInspectionRadiatorDate).getTime()) <= currentDate) {
            return true
        } else if ((new Date(item.nextInspectionInjectionPumpDate).getTime()) <= currentDate) {
            return true
        } else if ((new Date(item.nextTankCleaningDate).getTime()) <= currentDate) {
            return true
        } else if ((new Date(item.nextChangeBatteryDate).getTime()) <= currentDate) {
            return true
        } else if ((new Date(item.nextHorimetroChangeOilAndFilterDate).getTime()) <= currentDate) {
            return true
        } else if (expHorNumber <= parseInt(item.totalLastHorimetroChangeOilAndFilterRecordSeconds)) {
            return true
        } else {
            return false
        }

    }
    function expiration(expDate) {
        let expDateTime = new Date(expDate).getTime()
        if (expDate) {

            if (expDateTime <= currentDate) {
                return "service-expiration-color-red"
            } else if (((expDateTime - currentDate) >= 0) && ((expDateTime - currentDate) <= 2592000000)) {
                return "service-expiration-color-yellow"
            }

            /* let status = expiratioDateCalc(currentDate, expDate) */


            /*  if (!status) {
                 return "service-expiration-color"
             }
              */
        }
    }

    function expirationHorimetro(expHor) {
        let expHorNumber = 900000


        if (expHor) {
            if (expHorNumber <= parseInt(expHor)) {
                return "service-expiration-color-red"
            }
        }
    }
    return (

        <section className='list-service-expiration-container'>
            <div className="box-menu-horimetro">

                <div className="title">
                    Serviços e Prazos

                </div>
                <div className="box-menu-horimetro-option">
                    <div className="box-menu-horimetro-option-filter">
                        <Input
                            type="filter"
                            name="filterTable"
                            placeholder="Pesquisa"
                            handleOnChange={myFilter}
                        />
                    </div>

                </div>

            </div>
            <div className="box-table">
                <div className="box-table-header" >
                    <table className="table-horimetro">
                        <thead>
                            <tr>
                                <th className="first-th-radius">Modo de operação</th>
                                <th className='th-client'>Cliente</th>
                                <th >Radiador Sist. Arr.</th>
                                <th >Revisão de bomba injetora</th>
                                <th >Limpeza de tanque</th>
                                <th >Troca de bateria</th>
                                <th >Data da última troca de óleo e filtro</th>
                                <th >Horímetro da última troca de óleo e filtro</th>
                                <th >Data da próxima revisão</th>

                                <th className="last-th-radius">Horas funcionadas após ultima revisão</th>


                            </tr>

                        </thead>
                        <tbody className='tbody-service'>
                            {tableFilter ? (
                                tableFilter.map((item) => (

                                    <>
                                        {item.disabled !== true && (
                                            <>
                                                <tr >
                                                    <td colSpan="18" className="table-space"></td>
                                                </tr>
                                                <tr className="box-tr" onClick={() => { OpenModalClientServicesExpiration(item) }} >
                                                    {/* OPERATION MODE */}
                                                    <td>
                                                        {item.emergency && <img className="operation-mode-icon" src={iconEmergencia} />}
                                                        {anyExpire(item) ? (<img className="operation-mode-icon" src={iconManuVencida} />) : (<img className="operation-mode-icon" src={iconManuValida} />)}

                                                        {item.materialBest && (<img className="operation-mode-icon" src={iconMaterial} />)}
                                                        {item.rushHour && <img className="operation-mode-icon" src={iconHorario} />}




                                                    </td>



                                                    <td className="col-cliente">{item.clientName}</td>
                                                    <td className={expiration(item.nextInspectionRadiatorDate)} >{item.nextInspectionRadiatorDate ? monthYear(item.nextInspectionRadiatorDate) : "----------"}</td>
                                                    <td className={expiration(item.nextInspectionInjectionPumpDate)}>{item.nextInspectionInjectionPumpDate ? monthYear(item.nextInspectionInjectionPumpDate) : "----------"}</td>
                                                    <td className={expiration(item.nextTankCleaningDate)}>{item.nextTankCleaningDate ? monthYear(item.nextTankCleaningDate) : "----------"}</td>
                                                    <td className={expiration(item.nextChangeBatteryDate)}>{item.nextChangeBatteryDate ? monthYear(item.nextChangeBatteryDate) : "----------"}</td>
                                                    <td>{item.lastHorimetroChangeOilAndFilterDate ? monthYear(item.lastHorimetroChangeOilAndFilterDate) : "----------"}</td>
                                                    <td>{item.lastHorimetroChangeOilAndFilterRecordSeconds ? `${secondToHMinS(item.lastHorimetroChangeOilAndFilterRecordSeconds)}` : "----------"}</td>
                                                    <td className={expiration(item.nextHorimetroChangeOilAndFilterDate)}>{item.nextHorimetroChangeOilAndFilterDate ? monthYear(item.nextHorimetroChangeOilAndFilterDate) : "----------"}</td>
                                                    <td className={expirationHorimetro(item.totalLastHorimetroChangeOilAndFilterRecordSeconds)}>{item.totalLastHorimetroChangeOilAndFilterRecordSeconds ? `${secondToHMinS(item.totalLastHorimetroChangeOilAndFilterRecordSeconds)}` : "----------"}</td>

                                                </tr>
                                            </>
                                        )}
                                    </>



                                ))
                            ) : (<>
                                <tr >
                                    <td colSpan="18" className="table-space"></td>
                                </tr>
                                <tr className="box-tr"  >
                                    <td colSpan="18">Sem registro</td>

                                </tr>
                            </>)
                            }


                        </tbody>
                    </table>

                </div>

            </div>


            <div>
                {modalClientServicesExpiration &&
                    <>
                        <ModalFormServiceExpiration myPermission={myPermission} updateData={updateDataClient} clientData={dataModalClientServicesExpiration} listClient={listClient} closeModal={() => { setModalClientServicesExpiration(false) }} />
                    </>
                }
            </div>


        </section>

    )

}

export default ListServiceExpiration