
import { useState, useEffect } from "react"
import Select from 'react-select'
import { Link } from "react-router-dom"

import api from '../../../utils/api'
/* Hooks */
import useFlashMessage from '../../../hooks/useFlashMessage'
/* form */
import Input from "../../../shared/components/form/input"
import FormRegion from "../../region/form/FormRegion"
import Checkbox from "../../../shared/components/form/checkbox"


function FormClient({ handleSubmit, clientData, btnText, btnCancelLink, title }) {
    const defaultValue = {
        personType: "fisica"
    }

    const [client, setClient] = useState(clientData || {})
    const [selectOpen, setSelectOpen] = useState('')
    const [modalFormRegionRegister, setModalFormRegionRegister] = useState(false)
    const [regions, setRegions] = useState([])
    const [token] = useState(localStorage.getItem('token') || '')
    const { setFlashMessage } = useFlashMessage()
   
    useEffect(() => {
        getAll()
    }, [token])

    function handleChange(e) {

        setClient({ ...client, [e.target.name]: e.target.value })


    }
    function handleChangeCheckbox(name, value) {

        setClient({ ...client, [name]: value })
    
    
      }
    async function getAll() {
        api.get('/region/getall', {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`
            }
        })
            .then((response) => {
                setRegions(response.data.regions)
            })
    }
    const closeModalFormRegionRegister = () => {
        setModalFormRegionRegister(false)
    }
    async function registerRegion(region) {
        let msgType = 'success'



        const data = await api.post('/region/new', region, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`
            }
        }).then((response) => {
            setModalFormRegionRegister(false)
            getAll()
            return response.data
        })
            .catch((err) => {
                msgType = 'error'
                return err.response.data
            })






        setFlashMessage(data.message, msgType)



    }


    function submit(e) {
        e.preventDefault()
        
        handleSubmit(client)

    }

    let optionsRegion
    if (regions) {
        optionsRegion = regions.map((item) => (
            { ...optionsRegion, name: "region", value: item._id, label: item.regionName }
        )
        )
    }
    const selectStyles = {
        control: (styles) => ({ ...styles, borderColor: "black", borderRadius: "10px", border: "1px solid #9e9e9e", height: "41px" }),
    };
    const handleSelectChange = (selectedOption) => {
        
        setClient({ ...client, [selectedOption.name]: selectedOption.value })
    }
    function selectValue(id, array) {

        let value
        if (array) {
            array.map((i) => {
                
                if (i._id === id) {
                    value = i


                }
            })
        } else {
            value = ""
        }

        return value
    }


    return (
        <div className="container-form-client">
            <div className="center-form-client">
                <h1 className='shared-title-m1'>{title}</h1>
                <form onSubmit={submit} className="form-box-client ">
                    <div className="form-client-col-1 form-group">
                        <Checkbox

                            text="Cliente desabilitado"

                            name="disabled"

                            handleOnChange={() => handleChangeCheckbox("disabled", !client.disabled)}
                            check={client.disabled || false}
                        />

                    </div>

                    <div className="form-client-col-1 form-group">
                        <Input
                            styles="formGroup col400px"
                            text="Cliente"
                            type="text"
                            name="clientName"
                            placeholder="digite o nome do cliente"
                            handleOnChange={handleChange}
                            value={client.clientName || ""}
                        />





                    </div>





                    <div className="input-m-2 form-group">
                        <label className="input-label">Região:
                            <div onClick={() => { setModalFormRegionRegister(true) }}>
                                <div className="plus-sinal" >
                                    <div className="plus-sinal-tip"></div>
                                    <div className="plus-sinal-middle"></div>
                                    <div className="plus-sinal-tip"></div>
                                </div>
                            </div>
                        </label>
                        <Select
                            options={optionsRegion}
                            onChange={handleSelectChange}
                            styles={selectStyles}
                            value={selectValue(client.region, regions)}
                        />
                    </div>
                    <div className="form-group-m1 ">
                        <input className="bt-model-add bt-model-1 bt-width-m2" type="submit" value={btnText} />
                        <Link className="link-default" to={btnCancelLink}><div className="bt-model-cancel bt-model-2 bt-width-m2">Cancelar</div></Link>
                    </div>
                </form>
            </div>

            <div>
                {modalFormRegionRegister &&
                    <>
                        <FormRegion handleOnSubmit={registerRegion} closeModal={closeModalFormRegionRegister} title="Nova Região" btText="Adicionar" />
                    </>
                }

            </div>
        </div>
    )
}

export default FormClient